.BannerConArea {
    width: 100%;
    position: relative;
    height: 100%;
}

.BannerConArea::before {
    content: '';
    background-color: rgb(0 0 0 / 42%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.InnerBannerRow {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InnerBannerTitle {
    font-weight: 800;
    font-size: 78px;
    line-height: 110px;
    color: #fff;
    position: relative;
    text-shadow: 1px 1px 20px #00000066;
    text-align: center;
}

.FAQSec {
    width: 100%;
    padding: 120px 0 80px 0;
}

.FAQRow {
    width: 80%;
    margin: auto;
}

.FAQTitle {
    font-size: 18px;
    font-weight: 600;
    color: #4a4a4a;
}

.FAQCon {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    color: #707070;
    width: 100%;
    margin: 0 0 8px 0;
}

@media (min-width: 360px) {
    .InnerBannerTitle {
        font-size: 36px;
    }
    .FAQRow {
        width: 100%;
    }
}

@media (min-width: 480px) {}

@media (min-width: 576px) {
    .InnerBannerTitle {
        font-size: 48px;
    }
}

@media (min-width: 768px) {
    .InnerBannerTitle {
        font-size: 58px;
    }
}

@media (min-width: 992px) {
    .InnerBannerTitle {
        font-size: 68px;
    }
}

@media (min-width: 1200px) {
    .InnerBannerTitle {
        font-size: 78px;
    }
    .FAQRow {
        width: 80%;
    }
}

@media (min-width: 1400px) {}

@media (min-width: 1600px) {}

@media (min-width: 1800px) {}