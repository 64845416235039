.FooterMain {
    background: linear-gradient(142deg, rgba(0,0,97,1) 0%, rgba(9,59,155,1) 91%);
    padding: 60px 0 0 0;
    position: relative;
    overflow: hidden;
}

.FooterMain .GraImg {
    width: 600px;
    position: absolute;
    top: 0;
    left: -140px;
    opacity: .08;
}

.FootRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    /* padding-bottom: 60px; */
    border-bottom: 1px solid #2955a9;
    position: relative;
}

.FootLogo {
    width: 20%;
    min-width: 250px;
}

.FootLogo img {
    width: 100%;
    min-width: 250px;
    max-width: 250px;
    height: auto;
    object-fit: contain;
    object-position: center;
}

.FootLinkArea {
    width: 22%;
    display: inline-grid;
}

.FootLinkTitle {
    font-weight: 600;
    color: #ffffff;
    font-size: 23px;
    margin-bottom: 0.3rem;
    text-transform: uppercase;
    text-align: left;
}

.FootLink {
    width: fit-content;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #E3E3E3;
    text-decoration: none;
    padding: 10px 0;
    text-shadow: none;
    display: flex;
    align-items: flex-start;
    transition: all .3s ease-in-out;
}

.FootLink.TopAlign {
    align-items: flex-start;
    margin-bottom: 0;
}

.FootLink2 {
    width: fit-content;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #E3E3E3;
    text-decoration: none;
    padding: 0 0 10px 0;
    text-shadow: none;
    display: flex;
    align-items: center;
    transition: all .3s ease-in-out;
    width: 100%;
}

.FootLink2:last-of-type {
    padding: 0;
}

.FootLinkNormal {
    width: fit-content;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #E3E3E3;
    text-decoration: none;
    padding: 0 0 8px 0;
    text-shadow: none;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.FootLink svg {
    color: #44ac09;
    padding-top: 0px;
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 10px;
    margin-top: 2px;
}

.FootLink:hover,
.FootLink2:hover {
    color: #44ac09;
}

.FootLink img {
    width: 18px;
    margin-right: 12px;
    margin-top: 0px;
}

.FootTwoSec {
    display: inline-grid;
    flex-wrap: wrap;
}

.FootHours {
    width: 22%;
}

.FootTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #44ac09;
    margin-bottom: 8px;
}

.HoursSec {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.HoursSec li {
    list-style: none;
    padding: 8px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FAFAFA;
}

.HoursSec li span:first-child {
    display: inline-block;
    min-width: 110px;
}

.FootSocialIcon {
    width: 100%;
    margin-top: 20px;
}

.FootSocialIcon a {
    width: 30px;
    height: 30px;
    border: 1px solid #44ac09;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    transition: all .3s ease-in-out;
}

.FootSocialIcon a:hover {
    background-color: #44ac09;
}

.FootSocialIcon a svg {
    width: 20px;
    height: auto;
    stroke: none;
    fill: #44ac09;
    transition: all .3s ease-in-out;
}

.FootSocialIcon a:hover svg {
    fill: #fff;
}

.CopyRightText {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #fff;
    margin: 0;
    padding: 8px 0;
    text-align: center;
}

.FootSocialSec {
    width: 100%;
    padding: 40px 0;
    margin-top: 50px;
    border-top: 1px solid #2955a9;
}

.FootSocialSec ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.FootSocialSec ul li {
    list-style: none;
    padding-right: 50px;
}

.FootSocialSec ul li .SocialLinkTitle {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

.SocialIconName {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.SocialIconName img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.SocialIconName p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    transition: all .3s ease-in-out;
    text-decoration: none;
}

.SocialIconName:hover p {
    color: #44ac09;
}

.FootDRIcon {
    display: none;
}

@media (max-width: 991px) {
    .FootLink {
        width: 100%;
        /* justify-content: center; */
        padding-left: 25px;
    }
    .FootSocialSec ul li:first-child {
        width: 100%;
        text-align: center;
        padding-bottom: 15px;
    }
    .FooterMain .GraImg {
        opacity: .04;
    }
    .SocialIconName p {
        display: none;
    }
    .FootLinkArea,
    .FootHours {
        position: relative;
        overflow: hidden;
        min-height: 34px;
        max-height: 34px;
        transition: all .3s ease-in-out;
    }
    .FootLinkArea.Open,
    .FootHours.Open {
        min-height: 34px;
        max-height: 400px;
    }
    .FootDRIcon {
        display: block;
        position: absolute;
        top: 5px;
        right: 0;
        color: #fff;
    }
    .FootLinkArea.Open .FootDRIcon,
    .FootHours.Open .FootDRIcon {
        transform: rotate(180deg);
    }
    .FootDRIcon svg {
        width: 26px;
        height: 26px;
    }
    .FootRow {
        justify-content: center;
    }
}

@media (min-width: 360px) {
    .FootLogo {
        width: 100%;
        min-width: 250px;
        text-align: center;
        margin-bottom: 50px;
    }
    .FootLinkArea {
        width: 100%;
        margin-bottom: 35px;
    }
    .FootHours {
        width: 100%;
    }
    .FootSocialSec ul li {
        padding-bottom: 20px;
        padding-right: 0px;
        width: auto;
    }
}

@media (min-width: 480px) {}

@media (min-width: 576px) {}

@media (min-width: 768px) {
    .FootLinkArea {
        width: 70%;
    }
    .FootHours {
        width: 70%;
    }
}

@media (min-width: 992px) {
    .FootSocialSec ul li {
        padding-right: 25px;
        padding-bottom: 0px;
    }
    .FootLinkArea {
        width: 33%;
        margin-bottom: 0;
    }
    .FootHours {
        width: 33%;
    }
    .FootLinkTitle {
        text-align: left;
    }
}

@media (min-width: 1200px) {
    .FootLogo {
        width: 20%;
        min-width: 250px;
        text-align: left;
        margin-bottom: 0px;
    }
    .FootLinkArea {
        width: 22%;
    }
    .FootHours {
        width: 22%;
    }
    .FootSocialSec ul li {
        padding-right: 50px;
    }
}

@media (min-width: 1400px) {}