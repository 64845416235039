.HeaderMain {
    width: 100%;
    position: sticky;
    background: #fff;
    box-shadow: 0 5px 15px 0 #0000001a;
    top: 0;
    z-index: 99;
}

.HeaderRow {
    display: flex;
    height: 85px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.HeadLogo {
    max-width: 130px;
    max-height: 80px;
}

.HeadLogo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.HeadMenu {
    width: auto;
    position: relative;
    display: flex;
    align-items: center;
}

.HeadMenu ul {
    padding: 0;
    margin: 0;
    display: flex;
}

.HeadMenu ul li {
    list-style: none;
    /* font-family: "ProximaSemibold"; */
    font-size: 16px;
    font-weight: 600;
}

.HeadMenu ul li a {
    color: #333;
    padding: 30px 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.HeadMenu ul li a svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.HeadMenu ul li a svg.Chev {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    margin-right: 0px;
}

.HeaderBU {
    width: auto;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 25px;
    background: #008000;
    color: #fff;
    border: none;
    border-radius: 6px;
    margin-left: 15px;
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.HeaderBU span {
    position: relative;
    z-index: 1;
}

.HeaderBU:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background: #000066;
    z-index: 0;
}

.HeaderBU:hover:after {
    width: 100%;
    left: 0;
}

.MenuHamBU,
.MenuHamBU:hover {
    width: 50px;
    height: 50px;
    color: #000;
    border: none;
    background: no-repeat;
    padding: 0;
}

.MenuHamBU svg {
    width: 30px;
    height: 30px;
    stroke-width: 2px;
}

.UserDropMenu li {
    padding: 0;
}

.UserProfile,
.UserProfile:hover {
    padding: 10px 20px;
    font-size: 15px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #505050;
    font-weight: 500;
}

.UserProfile svg {
    width: 18px;
    height: 18px;
    margin-right: 12px;
}

.UserLogoutBU {
    padding: 10px 20px;
    font-size: 15px;
    text-decoration: none;
    display: flex;
    align-items: center;
    border: none;
    color: #505050;
    font-weight: 500;
    width: 100%;
    background: transparent;
}

.UserLogoutBU svg {
    width: 18px;
    height: 18px;
    margin-right: 12px;
}
.MenuFlag {
    width: 44px;
    height: auto;
    border-radius: 3px;
    margin-right: 8px;
}
.USDCurrency,
.USDCurrency:hover {
    padding: 10px 20px !important;
    font-size: 15px !important;
    text-decoration: none !important;
    display: flex !important;
    align-items: center !important;
    color: #505050 !important;
    font-weight: 500 !important;
}
@media (max-width: 991px) {
    .HeadMenu {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: fixed;
        width: 300px;
        right: -350px;
        top: 85px;
        background: #fff;
        height: Calc(100% - 80px);
        align-content: flex-start;
        box-shadow: -3px 10px 20px 0 rgb(51 51 51 / 18%);
        padding: 0 30px;
        transition: all .3s ease-in-out;
    }
    .HeadMenu.Open {
        right: 0;
    }
    .HeadMenu ul {
        flex-wrap: wrap;
        padding: 20px 0;
    }
    .HeadMenu ul li {
        width: 100%;
    }
    .HeadMenu ul li a {
        padding: 12px 0;
    }

    /*.HeadMenu ul li:last-child a {
        background: #ececec;
        border-radius: 6px;
        padding: 12px;
        margin-top: 10px;
    }*/
    .HeaderBU {
        width: 100%;
        margin-left: 0px;
    }
    .HeadMenu ul li a svg.Chev {
        margin-left: auto;
    }
}

@media (min-width: 360px) {}

@media (min-width: 480px) {}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {
    .MenuHamBU {
        display: none;
    }
    .HeadMenu ul li a {
        padding: 30px 10px;
    }
    .HeadMenu ul li {
        font-size: 14px;
    }
    .HeaderBU {
        font-size: 13px;
        padding: 12px 14px;
        margin-left: 8px;
    }
}

@media (min-width: 1200px) {
    .HeadMenu ul li a {
        padding: 30px 15px;
    }
    .HeadMenu ul li {
        font-size: 16px;
    }
    .HeaderBU {
        font-size: 16px;
        padding: 12px 25px;
        margin-left: 15px;
    }
}

@media (min-width: 1400px) {
    .HeadMenu ul li a {
        padding: 30px 20px;
    }
}