.BannerConArea {
  width: 100%;
  position: relative;
  height: 100%;
}

.BannerConArea::before {
  content: "";
  background-color: rgb(0 0 0 / 42%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.InnerBannerRow {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InnerBannerTitle {
  font-weight: 800;
  font-size: 78px;
  line-height: 110px;
  color: #fff;
  position: relative;
  text-shadow: 1px 1px 20px black;
}

.CarsAboutSec {
  width: 100%;
  padding: 70px 0 60px 0;
  background: #f3f3f3;
}

.AboutSubTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 44px;
  color: #4a4a4a;
  width: 100%;
  max-width: 1000px;
  text-align: center;
  text-align: center;
  margin: 0px auto;
  position: relative;
  padding-bottom: 10px;
}

.AddOnSec {
  width: 100%;
  padding: 80px;
  position: relative;
}

.AboutTitle {
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  color: #4a4a4a;
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 10px;
}

.AboutText {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #4a4a4a;
  width: 100%;
  max-width: 1100px;
  text-align: center;
  margin: 0px auto;
  position: relative;
  padding-top: 20px;
}

.ServicesRow {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin-top: 50px;
}

.ServicesCard {
  width: 48%;
  margin: 15px 0;
  background: #f3f7f3;
  padding: 30px 25px;
  /*box-shadow: 0 0 10px 0 #33333314;*/
  position: relative;
  overflow: hidden;
  border: 1px solid #eeefee;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  transition: all 0.3s ease-in-out;
}

.ServicesCard:hover {
  box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
}

.ServicesCard img {
  width: 50px;
  height: 50px;
  margin-bottom: 25px;
}

.ServiceCardTitle {
  font-size: 22px;
  color: #2a2a2a;
  line-height: 32px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  margin: 0 0 15px 0;
}

.ServiceText {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #878787;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
  position: relative;
}

.ServiceBU {
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 12px 20px;
  border: 1px solid #008000;
  color: #008000;
  margin-top: 25px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.ServiceBU:hover {
  background-color: #008000;
  border: 1px solid #008000;
  color: #fff;
}

@media (min-width: 360px) {
  .InnerBannerTitle {
    font-size: 36px;
  }
  .ServicesCard {
    width: 100%;
  }
  .CarsAboutSec {
    padding: 50px 0 40px 0;
  }  
  .AboutSubTitle {
    font-size: 18px;
    line-height: 32px;
  }
  .AddOnSec {
    padding: 50px 0;
  }  
  .AboutTitle {
    font-size: 32px;
    line-height: 46px;
  }
  .ServicesRow {
    width: 100%;
    margin-top: 20px;
  }
}

@media (min-width: 576px) {
  .InnerBannerTitle {
    font-size: 48px;
  }
}

@media (min-width: 768px) {
  .InnerBannerTitle {
    font-size: 58px;
  }
  .ServicesCard {
    width: 48%;
  }
  .CarsAboutSec {
    padding: 60px 0 50px 0;
  }  
  .AboutSubTitle {
    font-size: 20px;
    line-height: 38px;
  }
  .AddOnSec {
    padding: 60px 0;
  }  
  .AboutTitle {
    font-size: 38px;
    line-height: 52px;
  }
}

@media (min-width: 992px) {
  .InnerBannerTitle {
    font-size: 68px;
  }
  .ServicesCard {
    width: 48%;
  }
  .CarsAboutSec {
    padding: 70px 0 60px 0;
  }  
  .AboutSubTitle {
    font-size: 24px;
    line-height: 44px;
  }
  .AddOnSec {
    padding: 80px;
  }  
  .AboutTitle {
    font-size: 50px;
    line-height: 68px;
  }
  .ServicesRow {
    width: 90%;
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  .InnerBannerTitle {
    font-size: 78px;
  }
}
