.MainDiv {
    background-color:blueviolet;
    font-family: "ProximaThin";
}

.BannerConArea {
    width: 100%;
    position: relative;
    height: 100%;
}

.BannerConArea::before {
    content: '';
    background-color: rgba(0,0,102,.35);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.BannerConArea.Black::before {
    content: '';
    background-color: rgba(0,0,0,.35);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.BannerWave {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: -7px;
    left: 0;
}

.BannerContent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 700px;
    align-content: center;
    position: relative;
}

.BannerConText {
    width: 100%;
}

.BannerConTextB {
    color: #fff;
    font-size: 78px;
    font-weight: 600;
    line-height: 110px;
    margin: 0;
    text-shadow: 0 0 10px #00000047;
}

.BannerConTextS {
    color: #ececf1;
    font-size: 62px;
    font-weight: 200;
    margin: 0;
    text-shadow: 0 0 10px #00000047;
}

.BannerSearchArea {
    width: 100%;
    position: sticky;
    top: 120px;
    background: #fff;
    border-radius: 6px;
    padding: 26px 0;
    margin-top: 40px;
    box-shadow: 0 0 15px 0 #0000003b;
    /* transition: all .3s ease-in-out; */
}

.BannerSearchArea.BannerSearchAreaFixed {
    position: fixed;
    top: 47px;
    left: 0;
    border-radius: 0px;
    padding: 15px 0;
    background-color: rgb(0 0 102 / 80%);
    z-index: 98;
    animation: smoothScroll .5s forwards;
}

@keyframes smoothScroll {
	0% {
		transform: translateY(-70px);
	}
	100% {
		transform: translateY(0px);
	}
}

.BannerSearchArea .BannerSearchContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
    justify-content: space-between;
}

.BannerSearchLocation {
    width: 25%;
    /* border-right: 1px solid #D9D9D9; */
    padding: 0 20px;
}

.BannerSearchDate {
    width: 15%;
    /* border-right: 1px solid #D9D9D9; */
    padding: 0 20px;
}

.BannerSearchTime {
    width: 12%;
    padding: 0 20px;
    /* border-right: 1px solid #D9D9D9; */
}

.BannerSearchTime.BorderNone {
    border-right: none;
}

.BannerSearchArea.BannerSearchAreaFixed .BannerSearchLocation {
    border-right: 0px solid #D9D9D9;
    padding: 0 10px;
}

.BannerSearchArea.BannerSearchAreaFixed .BannerSearchDate {
    border-right: 0px solid #D9D9D9;
    padding: 0 10px;
}

.BannerSearchArea.BannerSearchAreaFixed .BannerSearchTime {
    padding: 0 10px;
    border-right: 0px solid #D9D9D9;
}

.BannerSearchLocation .FixedFullSec {
    padding: 5px 0 2px 15px;
    border: 1px solid #D9D9D9;
    margin-bottom: 0px;
}

.BannerSearchDate .FixedFullSec,
.BannerSearchTime .FixedFullSec {
    padding: 6px 0 6px 15px;
    border: 1px solid #D9D9D9;
    margin-bottom: 0px;
}

.BannerSearchLocation .FixedFullSec:last-of-type,
.BannerSearchDate .FixedFullSec:last-of-type,
.BannerSearchTime .FixedFullSec:last-of-type {
    margin-top: -1px;
}

.BannerSearchArea.BannerSearchAreaFixed .BannerSearchLocation .FixedFullSec,
.BannerSearchArea.BannerSearchAreaFixed .BannerSearchDate .FixedFullSec,
.BannerSearchArea.BannerSearchAreaFixed .BannerSearchTime .FixedFullSec {
    background: #fff;
    padding: 8px 15px 10px 15px;
    border-radius: 6px;
}

.BannerSearchButton {
    width: 20%;
    padding: 0 20px 0 0;
}

.BannerSearchArea.BannerSearchAreaFixed .BannerSearchButton {
    padding: 0 15px 0 5px;
}

.BannerFormLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #aaa;
    margin-bottom: 10px;
}

.BannerFormLabel.LabelCus {
    margin-bottom: 0;
}

.BannerSearchArea.BannerSearchAreaFixed .BannerFormLabel {
    margin-bottom: 8px;
}

.BannerSearchArea.BannerSearchAreaFixed .BannerFormLabel.LabelCus {
    margin-bottom: 0;
}

.FormSec {
    width: 100%;
    display: flex;
    align-items: center;
}

.FormSec svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.FormInput {
    width: 100%;
    border: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #585858;
}

.FormInput:focus {
    outline: none;
}

.SearchBU {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    background: #008000;
    border-radius: 8px;
    height: 69px;
    border: none;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.BannerSearchArea.BannerSearchAreaFixed .SearchBU {
    height: 65px;
}

.SearchBU span {
    position: relative;
    z-index: 1;
}

.SearchBU:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background: #000066;
    z-index: 0;
}

.SearchBU:hover:after {
    width: 100%;
    left: 0;
}

.BodyMessagesArea {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 130px 0 0 0;
    padding-bottom: 250px;
}

.BodyMRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
}

.BodyMHalfSec {
    width: 48%;
}

.BodyMHalfSecTitle {
    font-weight: 700;
    font-size: 48px;
    line-height: 68px;
    color: #4a4a4a;
    position: relative;
    margin-bottom: 35px;
    padding-bottom: 25px;
}

.BodyMHalfSecTitle:after {
    content: '';
    position: absolute;
    width: 80px;
    height: 4px;
    left: 0;
    bottom: 0;
    background: #008000;
}

.BodyMHalfSecText {
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    color: #7E7E7E;
    margin-bottom: 30px;
}

.LearnMoreBU {
    width: auto;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 25px;
    background: #7C4836;
    color: #fff;
    border: none;
    border-radius: 6px;
    margin-left: 0px;
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.LearnMoreBU span {
    position: relative;
    z-index: 1;
}

.LearnMoreBU:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background: #532d20;
    z-index: 0;
}
  
.LearnMoreBU:hover:after {
    width: 100%;
    left: 0;
}

.BodyMHalfSecCar {
    width: 48%;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
}

.CarBG {
    max-width: 850px;
    max-height: 700px;
    object-fit: contain;
    object-position: center;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
}

.BodyCar {
    width: 700px;
    height: auto;
    object-fit: cover;
    object-position: center;
    position: absolute;
    z-index: 2;
    right: -200px;
    top: 40%;
}

.TrendingWaveTop {
    width: 100%;
    height: auto;
    position: absolute;
    top: -1px;
    left: 0;
}

.TrendingWaveDown {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: -1px;
    left: 0;
}

.TrendingContent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 1100px;
    align-content: center;
    padding: 150px 0;
    position: relative;
}

.TrendingTitle {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #FFFFFF;
    text-shadow: 0 0 10px #00000047;
    width: 100%;
    text-align: center;
    max-width: 800px;
    margin: 0 auto 60px auto;
    position: relative;
    padding: 0 0 40px 0;
}

.TrendingTitle:after {
    content: '';
    position: absolute;
    width: 60px;
    height: 5px;
    left: 50%;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0 10px #00000047;
    transform: translate(-50%, 0);
}

.TrendingCarRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.TrendingCarSec {
    width: auto;
    background: #fff;
    border-radius: 4px;
    padding: 18px 16px;
    margin: 0 8px;
}

.TrendingCarSec img {
    width: 100%;
    height: 220px;
    object-fit: contain;
    object-position: center;
    border-radius: 8px;
    margin-bottom: 15px;
}

.TrendingCarConSec {
    width: 100%;
    padding: 0 15px;
}

.TrendingCarConTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #333;
    margin-bottom: 0;
}

.TrendingCarConTitleSM {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #878787;
    margin-bottom: 15px;
}

.TrendingCarConTitleSM span {
    position: relative;
    padding-right: 15px;
    margin-right: 8px;
}

.TrendingCarConTitleSM span:last-child {
    position: relative;
    padding-right: 0;
    margin-right: 0;
}

.TrendingCarConTitleSM span:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background: #878787;
    border-radius: 50%;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
}

.TrendingCarConTitleSM span:last-child:after {
    display: none;
}

.TrendingBUSec {
    width: 100%;
    padding-top: 15px;
}

.TrendingBUBrown {
    width: auto;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 22px;
    background: #7C4836;
    color: #fff;
    border: none;
    border-radius: 6px;
    margin-right: 12px;
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.TrendingBUBrown span {
    position: relative;
    z-index: 1;
}

.TrendingBUBrown:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background: #532d20;
    z-index: 0;
}
  
.TrendingBUBrown:hover:after {
    width: 100%;
    left: 0;
}

.TrendingBUGreen {
    width: auto;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 22px;
    background: #008000;
    color: #fff;
    border: none;
    border-radius: 6px;
    margin-left: 0px;
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.TrendingBUGreen span {
    position: relative;
    z-index: 1;
}

.TrendingBUGreen:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background: #015801;
    z-index: 0;
}
  
.TrendingBUGreen:hover:after {
    width: 100%;
    left: 0;
}

.CustomerReview {
    width: 100%;
    padding: 110px 0 60px 0;
}

.CustomerReviewTitle {
    font-weight: 700;
    font-size: 44px;
    line-height: 68px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 30px;
}

.CustomerReviewTitle:after {
    content: '';
    position: absolute;
    width: 60px;
    height: 5px;
    left: 50%;
    bottom: 0;
    background: #4a4a4a;
    transform: translate(-50%, 0);
}

.ReviewSlide {
    width: 100%;
    padding: 15px;
}

.ReviewSlideInner {
    width: 100%;
}

.ReviewSlideInnerImgDiv{
    width: 100%;
    height: 270px;
}

.ReviewSlideInnerImgDiv img {
    max-width: 100%;
    max-height: 270px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 20px;
    border-radius: 6px;
}

.ReviewSlideInnerTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #333;
    margin-bottom: 3px;
}

.ReviewSlideInnerTitleSM {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #878787;
    margin-top: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 150px;
    overflow: auto;
}

.ReviewProfile {
    width: 100%;
    display: flex;
    padding-top: 15px;
    align-items: flex-start;
    justify-content: space-between;
}

.ReviewProfileSec {
    width: auto;
    display: flex;
    align-items: center;
}

.ReviewProfileSec img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin-right: 15px;
    margin-bottom: 0;
}

.ReviewProfileDetails {
    width: auto;
}

.ReviewProfileDetails .Title {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #333;
    margin-bottom: 2px;
}

.ReviewProfileDetails .SubTitle {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #9a9a9a;
    margin-bottom: 0px;
}

.ReviewRatingSec {
    display: flex;
    align-items: center;
    margin: 0;
}

.ReviewRatingSec svg {
    width: 30px;
    stroke: none;
    fill: #E7AD44;
    margin-right: 0;
}

.ReviewRatingSec .Rating {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #333;
    margin-right: 8px;
}

.ReviewRatingSec .Count {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #9a9a9a;
}

.ReviewSlider {
    position: relative;
    padding-bottom: 80px;
}

.Mapbox {
    width: 100%;
    height: 550px;
}

.Mapbox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.BodyFull {
    width: 100%;
}

.WhyChooseTitleM {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 30px;
}

.WhyChooseRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin: auto;
}

.WhyChooseDiv {
    width: 25%;
    padding: 15px;
}

.WhyChooseUnder {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 30px 25px;
    box-shadow: 0 0 10px 0 #33333314;
    position: relative;
    overflow: hidden;
    border: 1px solid #dadada;
    border-radius: 8px;
    transition: all .3s ease-in-out;
}

.WhyChooseUnder:hover {
    box-shadow: 0px 20px 35px 8px rgb(51 51 51 / 19%);
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
}

.WhyChooseUnder img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    object-position: center;
    margin-bottom: 30px;
}

.WhyChooseTitle {
    margin-top: 0px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #4a4a4a;
    text-transform: capitalize;
    letter-spacing: 1px;
    position: relative;
    z-index: 2;
}

.WhyChooseText {
    margin-top: 0px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #8a8a8b;
    position: relative;
    z-index: 2;
}

.WhyBGGra {
    position: absolute;
    width: 200px !important;
    height: auto !important;
    left: -60px;
    bottom: -60px;
    margin: 0 !important;
    opacity: .04;
}

.MapBoxTitle {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 0px;
}

.FormInput > div {
    min-height: 36px;
    border: none;
}

.FormInput > div div span {
    display: none;
}

.OfferSec {
    position: absolute;
    top: 85px;
    z-index: 9;
    width: 500px;
    transform: translate(-50%, 0px);
    left: 50%;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    padding: 12px 20px 12px 20px;
    background: #a8f1a8;
    border-radius: 5px 5px 5px 5px;
    flex-wrap: wrap;
}

.OfferDiv{
    border-top: #000 1px solid;
    width: 80%;
}

.OfferTitle {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    margin-bottom: 10px;
}

.OfferTitle .Span1 {
    padding: 10px 8px 0 0;
}

.OfferTitle .Span2 {
    font-size: 36px;
    font-weight: 700;
}

.OfferTitle .Span3 {
    padding: 22px 0px 0 8px;
}

.OfferText {
    font-size: 15px;
    font-weight: 500;
    width: 80%;
    text-align: center;
    margin: 0 0 5px 0;
}

.OfferTextClick {
    cursor: pointer;
    text-align: right;
    text-decoration: underline;
}

.OfferCouponText{
    font-size: 17px;
    font-weight: 500;
    width: 80%;
    text-align: center;
    margin: 0 0 5px 0;
    color:#000066;
}

.OfferCouponBold{
    font-size: 20px;
    font-weight: 500;
    width: 80%;
    text-align: center;
    margin: 0 0 5px 0;
    color:#000066;
    font-weight: 800;
}

.OfferSecCloseBU {
    width: 34px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: transparent;
    border: none;
    position: absolute;
    top: 0px;
    right: 0px;
}

.OfferSecCloseBU svg {
    width: 20px;
    height: 20px;
}

.OfferSecCloseBU:hover,
.OfferSecCloseBU:focus {
    border: none;
    box-shadow: none;
    background: transparent;
}

.CouponApplyText {
    width: 100%;
    margin: 5px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #008000;
    font-weight: 500;
}

.CouponApplyText svg {
    width: 19px;
    height: auto;
    margin-right: 6px;
}

.ProductSlider {
    width: 100%;
}

@media (min-width: 360px) {
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchContainer {
        width: 100%;
    }
    .WhyChooseRow {
        width: 100%;
    }
    .WhyChooseDiv {
        width: 100%;
        padding: 15px;
    }
    .BodyMessagesArea {
        padding-bottom: 50px;
    }
    .TrendingCarConSec {
        padding: 0 0;
    }
    .TrendingBUBrown {
        padding: 10px 15px;
    }    
    .TrendingBUGreen {
        padding: 10px 15px;
    }
    /* .TrendingCarSec {
        width: 98%;
        margin: 2% 1%;
    } */
    .WhyChooseTitleM {
        font-size: 36px;
        line-height: 68px;
        margin-bottom: 20px;
        padding-bottom: 0px;
    }
    .BannerConTextB {
        font-size: 34px;
        line-height: 46px;
        text-align: center;
    }    
    .BannerConTextS {
        font-size: 20px;
        text-align: center;
    }
    .TrendingTitle {
        font-size: 36px;
        line-height: 58px;
        margin: 0 auto 30px auto;
        padding: 0 0 30px 0;
    }
    .CustomerReviewTitle {
        font-size: 36px;
        line-height: 58px;
        margin-bottom: 30px;
        padding-bottom: 30px;
    }
    .MapBoxTitle {
        font-size: 36px;
        line-height: 58px;
        margin-bottom: 20px;
        padding-bottom: 0px;
    }
    .BannerConArea.Top {
        height: 1000px !important;
    }
    .BannerContent {
        height: 1000px;
    }
    .OfferSec {
        width: 100%;
    }
    .OfferText {
        font-size: 14px;
        width: 100%;
    }
}

@media (min-width: 480px) {}

@media (min-width: 576px) {
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchContainer {
        width: 540px;
    }
    .WhyChooseDiv {
        width: 50%;
        padding: 15px;
    }
    .WhyChooseTitleM {
        font-size: 50px;
        line-height: 68px;
        margin-bottom: 40px;
        padding-bottom: 30px;
    }
    .BannerConTextB {
        font-size: 58px;
        line-height: 80px;
    }    
    .BannerConTextS {
        font-size: 46px;
    }
    .TrendingTitle {
        font-size: 50px;
        line-height: 68px;
        margin: 0 auto 60px auto;
        padding: 0 0 40px 0;
    }
    .CustomerReviewTitle {
        font-size: 44px;
        line-height: 68px;
        margin-bottom: 40px;
        padding-bottom: 30px;
    }
    .MapBoxTitle {
        font-size: 50px;
        line-height: 68px;
        margin-bottom: 40px;
        padding-bottom: 0px;
    }
    .BannerConArea.Top {
        height: 850px !important;
    }
    .BannerContent {
        height: 850px;
    }
    .BannerConTextB {
        font-size: 48px;
        line-height: 58px;
    }    
    .BannerConTextS {
        font-size: 28px;
    }
    .OfferSec {
        width: 500px;
    }
    .OfferText {
        font-size: 15px;
        width: 95%;
    }
}

@media (min-width: 768px) {
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchContainer {
        width: 720px;
    }
    /* .TrendingCarSec {
        width: 47%;
        margin: 1.1%;
    } */
    .BannerConTextB {
        font-size: 62px;
        line-height: 80px;
        text-align: left;
    }    
    .BannerConTextS {
        font-size: 34px;
        text-align: left;
    }
}

@media (min-width: 992px) {
    .BannerConTextB {
        font-size: 78px;
        line-height: 110px;
    }    
    .BannerConTextS {
        font-size: 44px;
    }
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchContainer {
        width: 960px;
    }
    .BannerSearchLocation {
        width: 34%;
        padding: 0 10px;
    }    
    .BannerSearchDate {
        width: 25%;
        padding: 0 10px;
    }    
    .BannerSearchTime {
        width: 20%;
        padding: 0 10px;
    }
    .BannerSearchButton {
        width: 21%;
        padding: 0 10px;
    }
    .BannerSearchArea {
        padding: 20px 5px;
    }
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchLocation {
        padding: 0 5px;
    }    
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchDate {
        padding: 0 5px;
    }    
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchTime {
        padding: 0 5px;
    }    
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchLocation .FixedFullSec {
        padding: 3px 15px 0px 15px;
        margin-bottom: 10px;
    }
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchDate .FixedFullSec,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchTime .FixedFullSec {
        padding: 3px 15px 7px 15px;
        margin-bottom: 10px;
    }
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchLocation .FixedFullSec:last-of-type,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchDate .FixedFullSec:last-of-type,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchTime .FixedFullSec:last-of-type {
        margin-bottom: 0;
    }
    .PopCloseBUSearch {
        display: none;
    }
    .WhyChooseDiv {
        width: 25%;
    }
    .BodyMessagesArea {
        padding-bottom: 250px;
    }
    /* .TrendingCarSec {
        width: 31%;
        margin: 0 1.1%;
    } */
    .TrendingBUBrown {
        padding: 10px 10px;
    }    
    .TrendingBUGreen {
        padding: 10px 10px;
    }
    .BannerContent {
        height: 800px;
    }
}

@media (min-width: 1200px) {
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchContainer {
        width: 1140px;
    }
    .BannerSearchLocation {
        width: 34%;
        padding: 0 16px;
    }    
    .BannerSearchDate {
        width: 22%;
        padding: 0 16px;
    }    
    .BannerSearchTime {
        width: 22%;
        padding: 0 16px;
    }
    .BannerSearchButton {
        width: 22%;
        padding: 0 16px;
    }
    /* .BannerSearchArea {
        padding: 26px 0;
    } */
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchLocation {
        padding: 0 10px;
    }    
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchDate {
        padding: 0 10px;
    }    
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchTime {
        padding: 0 10px;
    }    
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchLocation .FixedFullSec {
        padding: 3px 15px 0px 15px;
        margin-bottom: 10px;
    }
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchDate .FixedFullSec,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchTime .FixedFullSec {
        padding: 3px 15px 7px 15px;
        margin-bottom: 10px;
    }
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchLocation .FixedFullSec:last-of-type,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchDate .FixedFullSec:last-of-type,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchTime .FixedFullSec:last-of-type {
        margin-bottom: 0;
    }
    .WhyChooseRow {
        width: 100%;
    }
    .TrendingCarConSec {
        padding: 0 15px;
    }
    .TrendingBUBrown {
        padding: 10px 15px;
    }    
    .TrendingBUGreen {
        padding: 10px 15px;
    }
}

@media (min-width: 1400px) {
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchContainer {
        width: 1320px;
    }    
    .BannerSearchDate {
        width: 22%;
    }    
    .BannerSearchTime {
        width: 22%;
    }
    .WhyChooseRow {
        width: 90%;
    }
    .TrendingBUBrown {
        padding: 10px 22px;
    }    
    .TrendingBUGreen {
        padding: 10px 22px;
    }
}

@media (min-width: 1600px) {}

@media (min-width: 1800px) {}

@media (max-width: 991px) {
    .BannerSearchArea,
    .BannerSearchArea.BannerSearchAreaFixed {
        position: relative;
        left: unset;
        width: 100%;
        top: 60px;
        margin: 0;
        padding: 20px 0;
        transform: unset;
        animation: none;
        background-color: #fff;
        border-radius: 6px;
        transition: all .5s ease-in-out;
    }
    .BannerSearchArea.Open,
    .BannerSearchArea.BannerSearchAreaFixed.Open {
        transform: translate(-50%, -50%) translateY(0);
    }
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchContainer {
        width: 100%;
    }
    .BannerSearchLocation,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchLocation {
        width: 100%;
        /* border-right: 0px solid #D9D9D9;
        border-bottom: 1px solid #D9D9D9; */
        padding: 0 20px 15px 20px;
    }    
    .BannerSearchDate,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchDate {
        width: 50%;
        /* border-right: 1px solid #D9D9D9;
        border-bottom: 1px solid #D9D9D9; */
        padding: 15px 20px 15px 20px;
    }    
    .BannerSearchTime,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchTime {
        width: 50%;
        padding: 15px 20px 15px 20px;
        /* border-right: 0px solid #D9D9D9;
        border-bottom: 1px solid #D9D9D9; */
    }
    .BannerSearchButton,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchButton {
        width: 100%;
        padding: 20px 20px 0 20px;
    }
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchLocation .FixedFullSec,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchDate .FixedFullSec,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchTime .FixedFullSec {
        background: #fff;
        padding: 0;
        border-radius: 0;
    }
    .PopCloseBUSearch {
        display: flex;
        position: absolute;
        width: 30px;
        height: 30px;
        right: -15px;
        top: -15px;
        background: #0c42cb;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        color: #fff;
        box-shadow: 0 0 12px 0 #00000091;
        transition: all .3s ease-in-out;
    }
    .PopCloseBUSearch svg {
        width: 18px;
        height: 18px;
    }

    .PopCloseBUSearch:hover {
        background: #008000;
        color: #fff;
    }
}

@media (max-width: 479px) {
    .BannerSearchDate,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchDate {
        width: 100%;
        /* border-right: 1px solid #D9D9D9;
        border-bottom: 1px solid #D9D9D9; */
        padding: 15px 20px 15px 20px;
    }    
    .BannerSearchTime,
    .BannerSearchArea.BannerSearchAreaFixed .BannerSearchTime {
        width: 100%;
        padding: 15px 20px 15px 20px;
        /* border-right: 0px solid #D9D9D9;
        border-bottom: 1px solid #D9D9D9; */
    }
}

.FormInputError{
    border: #ff0000 1px solid;
}