.BannerConArea {
    width: 100%;
    position: relative;
    height: 100%;
}

.BannerConArea::before {
    content: '';
    background-color: rgb(0 0 0 / 42%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.InnerBannerRow {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InnerBannerTitle {
    font-weight: 800;
    font-size: 78px;
    line-height: 110px;
    color: #fff;
    position: relative;
    text-shadow: 1px 1px 20px #0000006e;
}

.AboutSec {
    width: 100%;
    padding: 150px 0 50px 0;
}

.AboutSecRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.AboutSecBig {
    width: 55%;
}

.AboutTitle {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #4a4a4a;
    width: 100%;
    text-align: left;
    padding: 0;
    margin: 0 0 20px 0;
}

.AboutText {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #4a4a4a;
    width: 100%;
    text-align: left;
    margin: 0 0 15px 0;
}

.AboutSecSmall {
    width: 40%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}

.AboutImg {
    border-radius: 5px;
    position: relative;
    z-index: 2;
}

.BodyMessagesArea {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 130px 0 0 0;
    padding-bottom: 250px;
}

.CarBG {
    max-width: 760px;
    max-height: 700px;
    object-fit: contain;
    object-position: center;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
}

.BodyMRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
}

.BodyFull {
    width: 100%;
}

.WhyChooseTitleM {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 30px;
}

.WhyChooseRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    margin: auto;
}

.WhyChooseDiv {
    width: 33.33%;
    padding: 15px;
}

.WhyChooseUnder {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 30px 25px;
    box-shadow: 0 0 10px 0 #33333314;
    position: relative;
    overflow: hidden;
    border: 1px solid #dadada;
    border-radius: 8px;
    transition: all .3s ease-in-out;
}

.WhyChooseUnder:hover {
    box-shadow: 0px 20px 35px 8px rgb(51 51 51 / 19%);
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
}

.WhyChooseUnder img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    object-position: center;
    margin-bottom: 30px;
}

.WhyChooseTitle {
    margin-top: 0px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #4a4a4a;
    text-transform: capitalize;
    letter-spacing: 1px;
    position: relative;
    z-index: 2;
}

.WhyChooseText {
    margin-top: 0px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #8a8a8b;
    position: relative;
    z-index: 2;
}

.WhyBGGra {
    position: absolute;
    width: 200px !important;
    height: auto !important;
    left: -60px;
    bottom: -60px;
    margin: 0 !important;
    opacity: .04;
}

.InsuranceSec {
    width: 100%;
    height: auto;
    background: #f3f3f3;
    position: relative;
    padding: 250px 0 100px 0;
}

.waveInsur {
    width: 100%;
    height: auto;
    position: absolute;
    top: -1px;
    left: 0;
}

.InsurTitle {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0 0 40px 0;
    text-transform: uppercase;
}

.InsurSmallTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.InsurAmount {
    width: fit-content;
    border: 3px solid #212529;
    font-size: 38px;
    margin: 0px auto;
    font-weight: 400;
    padding: 10px 30px;
    background: #212529;
    color: #fff;
    border-radius: 60px;
}

.ButtonArea {
    width: 100%;
    display: flex;
    justify-content: center;
}

.LearnMoreBU {
    width: auto;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 25px;
    background: #7C4836;
    color: #fff;
    border: none;
    border-radius: 6px;
    margin: 50px 0 0 0;
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.LearnMoreBU span {
    position: relative;
    z-index: 1;
}

.LearnMoreBU:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background: #532d20;
    z-index: 0;
}
  
.LearnMoreBU:hover:after {
    width: 100%;
    left: 0;
}

.AboutMissionVissionSec {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.HalfSecMVS {
    width: 45%;
}

@media (min-width: 360px) {
    .BodyMessagesArea {
        padding-bottom: 50px;
    }
    .WhyChooseTitleM {
        font-size: 36px;
        line-height: 68px;
        margin-bottom: 20px;
        padding-bottom: 0px;
    }
    .WhyChooseRow {
        width: 100%;
    }
    .WhyChooseDiv {
        width: 100%;
        padding: 15px;
    }
    .ProductTitle,
    .ServiceTitle,
    .InsurTitle {
        font-size: 28px;
        line-height: 48px;
    }
    .InnerBannerTitle {
        font-size: 36px;
        line-height: 60px;
    }
    .AboutSecBig {
        width: 100%;
    }
    .AboutSecSmall {
        width: 100%;
    }
    .HalfSecMVS {
        width: 100%;
    }
    .AboutImg {
        width: 100%;
    }
}

@media (min-width: 576px) {
    .WhyChooseTitleM {
        font-size: 50px;
        line-height: 68px;
        margin-bottom: 40px;
        padding-bottom: 30px;
    }
    .WhyChooseDiv {
        width: 50%;
        padding: 15px;
    }
    .ProductTitle,
    .ServiceTitle,
    .InsurTitle {
        font-size: 40px;
        line-height: 58px;
    }
    .InnerBannerTitle {
        font-size: 48px;
        line-height: 80px;
    }
    .AboutImg {
        width: auto;
    }
}

@media (min-width: 768px) {
    .InnerBannerTitle {
        font-size: 58px;
        line-height: 100px;
    }
    .ProductSmallTitle {
        width: 600px;
    }
}

@media (min-width: 992px) {
    .BodyMessagesArea {
        padding-bottom: 150px;
    }
    .WhyChooseDiv {
        width: 33.33%;
    }
    .ProductTitle,
    .ServiceTitle,
    .InsurTitle {
        font-size: 36px;
        line-height: 68px;
    }
    .InnerBannerTitle {
        font-size: 68px;
        line-height: 110px;
    }
    .AboutSecBig {
        width: 55%;
    }
    .AboutSecSmall {
        width: 40%;
    }
    .HalfSecMVS {
        width: 45%;
    }
}

@media (min-width: 1200px) {
    .WhyChooseRow {
        width: 90%;
    }
    .InnerBannerTitle {
        font-size: 78px;
    }
    .ProductTitle,
    .ServiceTitle,
    .InsurTitle {
        font-size: 42px;
        line-height: 68px;
    }
}

@media (min-width: 1400px) {
    .WhyChooseRow {
        width: 80%;
    }
    .ProductTitle,
    .ServiceTitle,
    .InsurTitle {
        font-size: 50px;
        line-height: 68px;
    }
}

@media (min-width: 1800px) {}