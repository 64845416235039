.MainDiv {
    width: 100%;
}

.PageTitleWrap {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ebebeb;
    box-shadow: 0 3px 10px rgb(62 85 120 / 7%);
    padding: 1rem 1.8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.PageTitle {
    margin: 0;
    color: #505050;
    font-size: 18px;
    font-weight: 500;
}

.MainCard {
    margin: 0 1.8rem 80px;
}

.AddUserMain {
    width: 800px;
    padding: 15px;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: .875rem;
}

.FormGroup {
    width: 48%;
    padding-bottom: 20px;
    font-size: .875rem;
    position: relative;
}

.FormLabel {
    font-size: 13px;
    line-height: 30px;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.FormControl {
    display: block;
    width: 100%;
    min-width: 150px;
    height: 38px;
    padding: 0.375rem 0.4rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.FormControl:focus {
    outline: none;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 0;
}

.FormButtonArea {
    width: 100%;
}

.CancelBU {
    padding: 12px 30px;
    background-color: #fff;
    margin: 15px 15px 0 0;
    font-size: 14px;
    width: auto;
    font-weight: 600;
    line-height: 14px;
    border-radius: 0.5rem;
    color: #006;
    border: 1px solid #006;
    text-align: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
    outline: none;
    display: inline-block;
}

.CancelBU:hover {
    background-color: #006;
    color: #fff;
}

.SubmitBU {
    padding: 12px 30px;
    background-color: #006;
    margin: 15px 15px 0 0;
    font-size: 14px;
    width: auto;
    font-weight: 600;
    line-height: 14px;
    border-radius: 0.5rem;
    color: #fff;
    text-align: center;
    border: 1px solid #006;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
    outline: none;
}

.SubmitBU:hover {
    background-color: #010133;
    border: 1px solid #010133;
}

.Card {
    min-height: 125px;
    box-shadow: 0 0px 7px 2px rgb(62 85 120 / 10%);
    border-radius: 0.5rem;
    margin-bottom: 1.875rem;
    position: relative;
    background: white;
    border: none;
}

.CardBody {
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.CardHead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.CardTitle {
    font-size: 16px !important;
    text-transform: capitalize !important;
    display: flex;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 12px;
    font-weight: 500;
    color: #505050;
}

.CardTitle svg {
    width: 20px;
    height: 20px;
    stroke-width: 1.5;
    margin-left: 5px;
    color: #006;
}

.CardValue {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0;
    color: #505050;
}

.CardIcon {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 102 / 84%);
}

.CardIcon img {
    max-width: 30px;
    max-height: 30px;
}

.DoughnutChart {
    width: 300px !important;
    height: 300px !important;
    margin: auto;
}

.BarChart {
    height: 640px !important;
}

.UpcomingBookTable {
    width: 100%;
    height: 220px;
    overflow: auto;
}

.UpcomingBookTable table {
    width: 100%;
}

.TableHead {
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #efefef;
    padding: 8px 6px;
}

.TableBody {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 6px;
    border-bottom: 1px solid #efefef;
    color: #6a6a6a;
}

.UpcomingBookTable table tr:nth-child(odd) {
    background-color: #fff;
}

.UpcomingBookTable table tr:nth-child(even) {
    background-color: #f8f8ff;
}