.BannerConArea {
    width: 100%;
    position: relative;
    height: 100%;
}

.BannerConArea::before {
    content: '';
    background-color: rgb(0 0 0 / 42%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.InnerBannerRow {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InnerBannerTitle {
    font-weight: 800;
    font-size: 78px;
    line-height: 110px;
    color: #fff;
    position: relative;
    text-shadow: 1px 1px 20px black;
}

.CarsAboutSec {
    width: 100%;
    padding: 90px 0 80px 0;
    background: #f3f3f3;
}

.AboutTitle {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    margin-bottom: 0px;
    position: relative;
    padding-bottom: 10px;
}

.AboutSubTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 44px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    margin-bottom: 0px;
    position: relative;
    padding-bottom: 10px;
}

.AboutText {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #4a4a4a;
    width: 100%;
    max-width: 1100px;
    text-align: center;
    margin: 0px auto;
    position: relative;
    padding-top: 20px;
}

.CarsCateSec {
    width: 100%;
    padding: 70px 0 70px 0;
}

.CarsCateSecCompact {
    width: 100%;
}

.CarsCateSecTitle {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 30px;
}

.CarsCateSecSubTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #4a4a4a;
    width: 100%;
    max-width: 1100px;
    text-align: center;
    margin: 0px auto;
    position: relative;
    padding-bottom: 15px;
}

.CarsCateSecSubText {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #4a4a4a;
    width: 100%;
    max-width: 1000px;
    text-align: center;
    margin: 0px auto;
    position: relative;
    padding-bottom: 30px;
}

.ProductSlider {
    width: 100%;
}

.ProductSlider2 {
    width: 100%;
    padding-top: 40px;
}

.Item {
    width: 90%;
    margin: 15px auto;
    position: relative;
}

.ItemUnder {
    align-items: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.CardTexture {
    position: absolute;
    width: 600px;
    top: -200px;
    right: -310px;
    opacity: .7;
}

.ItemUnder img.CardIMG {
    width: 100%;
    height: 150px;
    object-fit: contain;
    object-position: center;
    margin-bottom: 35px;
    position: relative;
    mix-blend-mode: darken;
}

.CarItemTitle {
    color: #2a2a2a;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
    text-transform: capitalize;
    text-transform: capitalize;
    text-align: center;
    width: 100%;
    position: relative;
}

.CarItemText {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #878787;
    margin-bottom: 2px;
    width: 100%;
    text-align: center;
    position: relative;
}

.CarItemText2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #878787;
    margin-bottom: 25px;
    width: 100%;
    text-align: center;
    position: relative;
}

.CarItemPrice {
    color: #2a2a2a;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    margin: 0 0 20px 0;
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.CarItemPrice span {
    color: #5a5a5a;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    margin-left: 10px;
    position: relative;
}

.TrendingBUGreen {
    width: 100%;
    max-width: 200px;
    font-size: 14px;
    font-weight: 600;
    padding: 14px 22px;
    background: #008000;
    color: #fff;
    border: none;
    border-radius: 6px;
    margin: auto;
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.TrendingBUGreen span {
    position: relative;
    z-index: 1;
}

.TrendingBUGreen:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background: #015801;
    z-index: 0;
}
  
.TrendingBUGreen:hover:after {
    width: 100%;
    left: 0;
}

.CardTabBUSec {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px 10px 30px 10px;
    justify-content: center;
}

.CardTabBUSec button {
    width: 33.33%;
    font-size: 20px;
    font-weight: 600;
    padding: 20px 15px;
    background-color: #fff;
    color: #008000;
    margin: -1px -1px;
    border-radius: 0px;
    border: 1px solid #008000;
    transition: all .3s ease-in-out;
}

.CardTabBUSec button.Active {
    background-color: #008000;
    color: #fff;
}

.CardTabBUSec button:hover {
    background-color: #008000;
    color: #fff;
}

.CardTabBUSec button img {
    width: 40px;
    height: auto;
    margin-right: 10px;
}

.CardTabBUSec button.Active img,
.CardTabBUSec button:hover img {
    filter: brightness(0) invert(1);
}

.WhyUsSec {
    width: 100%;
    padding: 80px 0;
    position: relative;
}

.CarBG {
    max-width: 850px;
    max-height: 700px;
    object-fit: contain;
    object-position: center;
    position: absolute;
    z-index: 0;
    right: 0;
    bottom: 200px;
}

.WhyChooseRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin: 50px auto 0 auto;
}

.WhyChooseDiv {
    width: 25%;
    padding: 15px;
}

.WhyChooseUnder {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 30px 25px;
    box-shadow: 0 0 10px 0 #33333314;
    position: relative;
    overflow: hidden;
    border: 1px solid #dadada;
    border-radius: 8px;
    /* transition: all .3s ease-in-out; */
}

/* .WhyChooseUnder:hover {
    box-shadow: 0px 20px 35px 8px rgb(51 51 51 / 19%);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
} */

.WhyChooseUnder img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    object-position: center;
    margin-bottom: 30px;
}

.WhyChooseTitle {
    margin-top: 0px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #4a4a4a;
    text-transform: capitalize;
    letter-spacing: 1px;
    position: relative;
    z-index: 2;
}

.WhyChooseText {
    margin-top: 0px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #8a8a8b;
    position: relative;
    z-index: 2;
}

.WhyBGGra {
    position: absolute;
    width: 200px !important;
    height: auto !important;
    left: -60px;
    bottom: -60px;
    margin: 0 !important;
    opacity: .04;
}

.InsuranceSec {
    width: 100%;
    height: auto;
    background: #f3f3f3;
    position: relative;
    padding: 200px 0 100px 0;
}

.waveInsur {
    width: 100%;
    height: auto;
    position: absolute;
    top: -1px;
    left: 0;
}

.ProductHead {
    width: 100%;
    text-align: center;
    /* margin-bottom: 40px;
    position: relative;
    padding-bottom: 30px; */
}

.InsurTitle {
    font-weight: 700;
    font-size: 42px;
    line-height: 58px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0 0 20px 0;
}

.InsurSmallTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    max-width: 900px;
    text-align: center;
    margin: 0 auto;
}

.InsurSmallTitle a {
    display: inline-block;
    color: #44ac09;
    text-decoration: none;
    margin-left: 5px;
}

@media (min-width: 360px) {
    .InnerBannerTitle {
        font-size: 36px;
    }
    .WhyChooseRow {
        width: 100%;
    }
    .WhyChooseDiv {
        width: 100%;
        padding: 15px;
    }
    .CarsAboutSec {
        padding: 50px 0 40px 0;
    }    
    .AboutTitle {
        font-size: 32px;
        line-height: 46px;
        padding-bottom: 10px;
    }    
    .AboutSubTitle {
        font-size: 18px;
        line-height: 30px;
        padding-bottom: 10px;
    }    
    .AboutText {
        font-size: 15px;
        line-height: 25px;
        padding-top: 10px;
    }
    .CarsCateSec {
        padding: 50px 0 50px 0;
    }
    .CarsCateSecTitle {
        font-size: 38px;
        line-height: 48px;
        padding-bottom: 10px;
    }
    .CardTabBUSec button {
        width: 100%;
        font-size: 18px;
    }
    .CarsCateSecSubText {
        font-size: 15px;
        line-height: 25px;
        padding-top: 10px;
    }
    .WhyUsSec {
        padding: 50px 0;
    }
    .WhyChooseTitle {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .WhyChooseText {
        font-size: 15px;
        line-height: 24px;
    }
    .InsurTitle {
        font-size: 34px;
        line-height: 42px;
    }    
    .InsurSmallTitle {
        font-size: 15px;
        line-height: 22px;
    }
    .InsuranceSec {
        padding: 100px 0 50px 0;
    }
}

@media (min-width: 576px) {
    .InnerBannerTitle {
        font-size: 48px;
    }
}

@media (min-width: 768px) {
    .InnerBannerTitle {
        font-size: 58px;
    }
    .WhyChooseDiv {
        width: 50%;
        padding: 15px;
    }
    .CarsAboutSec {
        padding: 70px 0 60px 0;
    }    
    .AboutTitle {
        font-size: 46px;
        line-height: 64px;
        padding-bottom: 10px;
    }    
    .AboutSubTitle {
        font-size: 22px;
        line-height: 28px;
        padding-bottom: 10px;
    }    
    .AboutText {
        font-size: 16px;
        line-height: 26px;
        padding-top: 15px;
    }
    .CarsCateSec {
        padding: 60px 0 60px 0;
    }
    .CarsCateSecTitle {
        font-size: 46px;
        line-height: 64px;
        padding-bottom: 20px;
    }
    .CardTabBUSec button {
        width: 33.33%;
    }
    .CarsCateSecSubText {
        font-size: 16px;
        line-height: 26px;
        padding-bottom: 20px;
    }
    .WhyUsSec {
        padding: 50px 0;
    }
    .WhyChooseTitle {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    .InsurTitle {
        font-size: 38px;
        line-height: 48px;
    }    
    .InsurSmallTitle {
        font-size: 16px;
        line-height: 26px;
    }
    .InsuranceSec {
        padding: 150px 0 70px 0;
    }
}

@media (min-width: 992px) {
    .InnerBannerTitle {
        font-size: 68px;
    }
    .CarsAboutSec {
        padding: 90px 0 80px 0;
    }    
    .AboutTitle {
        font-size: 50px;
        line-height: 68px;
        padding-bottom: 10px;
    }    
    .AboutSubTitle {
        font-size: 24px;
        line-height: 44px;
        padding-bottom: 10px;
    }    
    .AboutText {
        font-size: 18px;
        line-height: 32px;
        padding-top: 20px;
    }
    .CarsCateSec {
        padding: 70px 0 70px 0;
    }
    .CarsCateSecTitle {
        font-size: 50px;
        line-height: 68px;
        padding-bottom: 30px;
    }
    .CardTabBUSec button {
        font-size: 20px;
    }
    .CarsCateSecSubText {
        font-size: 18px;
        line-height: 28px;
        padding-bottom: 30px;
    }
    .WhyUsSec {
        padding: 80px 0;
    }
    .WhyChooseTitle {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .WhyChooseText {
        font-size: 16px;
        line-height: 28px;
    }
    .InsurTitle {
        font-size: 42px;
        line-height: 58px;
    }    
    .InsurSmallTitle {
        font-size: 18px;
        line-height: 32px;
    }
    .InsuranceSec {
        padding: 200px 0 100px 0;
    }
}

@media (min-width: 1200px) {
    .InnerBannerTitle {
        font-size: 78px;
    }
    .WhyChooseRow {
        width: 100%;
    }
    .WhyChooseDiv {
        width: 33.33%;
    }
}

@media (min-width: 1400px) {}