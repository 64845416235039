.MainDiv {
    background-color:aquamarine;
}

.BannerConArea {
    width: 100%;
    position: relative;
    height: 100%;
}

.BannerConArea::before {
    content: '';
    background-color: rgb(0 0 0 / 42%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.InnerBannerRow {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InnerBannerTitle {
    font-weight: 800;
    font-size: 78px;
    line-height: 110px;
    color: #fff;
    position: relative;
    text-shadow: 1px 1px 20px black;
}

.SelectCarSec {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.SmallSecSide {
    width: 32%;
}

.SmallSecSideCard {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 22px 16px;
    margin-bottom: 30px;
}

.SmallSecSideTitle {
    margin: 0 0 15px 0;
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

.SmallCardDESec {
    width: 100%;
    margin-bottom: 25px;
}

.SmallCardDESec:last-child {
    margin-bottom: 0;
}

.SmallCardDESecHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.SmallCardDESecTitle {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.SideEditBU {
    border: 1px solid #008000;
    font-size: 12px;
    font-weight: 600;
    border-radius: 30px;
    padding: 5px 12px;
    color: #008000;
    background: #fff;
    transition: all .3s ease-in-out;
}

.SideEditBU:hover {
    color: #fff;
    background: #008000;
}

.SmallCardDEList {
    margin: 0 0 5px 0;
    font-size: 13px;
    font-weight: 500;
    color: #606060;
}

.ReservationBigSide {
    width: 66%;
}

.AddonsBigSide {
    width: 66%;
    height: fit-content;
    box-shadow: 0px 1px 6px rgb(0 0 0 / 16%);
    border-radius: 8px;
    padding: 28px;
}

.BigSideHead {
    width: 100%;
    background-color: #F1F1F1;
    border-radius: 6px;
    padding: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BigHeadTitleText {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #000;
    margin: 0 0 2px 0;
}

.BigHeadSmallText {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ABABAB;
    margin: 0;
}

.BigHeadFilter {
    display: flex;
    align-items: center;
}

.BigHeadFilterTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin: 0 15px 0 0;
    text-transform: uppercase;
}

.FormInput {
    width: 160px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #585858;
}

.FormInput:focus {
    outline: none;
}

.ReservationBigListSec{
    width: 100%;
}

.BigSideCarList {
    width: 100%;
    padding: 10px 0;
}

.BigSideCarListUnder {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0px 1px 6px rgb(0 0 0 / 16%);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.BigSideCarListUnder.Selected::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 5px solid #008000;
    left: 0;
    top: 0;
    border-radius: 8px;
}

.BigSideCarListUnder.Selected::before {
    content: 'Current Selection';
    position: absolute;
    background-color: #008000;
    color: #fff;
    left: 2px;
    top: 2px;
    font-size: 14px;
    font-weight: 500;
    padding: 2px 8px;
    border-bottom-right-radius: 8px;
}

.BigSideCarListUnder img {
    width: 160px;
    height: auto;
    margin-right: 30px;
}

.ReservCarDeSec {
    position: relative;
    z-index: 1;
}

.ReservCarDeTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    color: #2a2a2a;
    margin: 0 0 0 0;
}

.ReservCarDeSTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #959595;
    margin: 0 0 10px 0;
}

.CarFeatured {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.CarFeatured span {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #5a5a5a;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.CarFeatured span:last-child {
    margin-right: 0;
}

.CarFeatured span img {
    width: 16px;
    height: 16px;
    margin-right: 7px;
}

.CarInfoLink {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #006 !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.CarInfoLink::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 1px;
    bottom: -3px;
    left: 28px;
    background: #006;
    transition: all .3s ease-in-out;
}

.CarInfoLink:hover {
    color: #006 !important;
}

.CarInfoLink:hover::after {
    width: 104px;
}

.CarInfoLink svg {
    width: 20px;
    height: 20px;
    margin-right: 7px;
}

.ReservCarPriceSec {
    position: relative;
    margin-left: auto;
    padding-right: 30px;
}

.ReservCarPrice {
    position: relative;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    color: #2a2a2a;
    margin: 0 0 0 0;
}

.ReservCarPrice:before {
    content: '$';
    position: absolute;
    font-size: 15px;
    color: #2a2a2a;
    font-weight: 500;
    top: -2px;
    left: -16px;
}

.ReservCarPriceTwo {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #5a5a5a;
}

.HeaderBU {
    width: auto;
    font-size: 15px;
    font-weight: 500;
    padding: 18px 30px;
    background-color: #7C4836;
    color: #fff;
    letter-spacing: .5px;
    border: none;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    margin: 15px 0 0 0;
    transition: all .3s ease-in-out;
}

.HeaderBU span {
    position: relative;
    z-index: 1;
}

.HeaderBU:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 0;
}

.HeaderBU:hover:after {
    width: 100%;
    left: 0;
}

.HeaderBUSE,
.HeaderBUSE:hover {
    width: auto;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 30px;
    background-color: #008000;
    color: #fff;
    letter-spacing: .5px;
    border: none;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
    cursor: default;
}

.AddonsBodyTitle {
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

.AddonsBodySTitle {
    font-size: 15px;
    font-weight: 600;
    color: #008000;
    margin-bottom: 8px;
}

.AddonsBodyList {
    margin: 0 0 0 12px;
    padding: 0;
}

.AddonsBodyList li {
    font-size: 14px;
    font-weight: 500;
    color: #606060;
    list-style: none;
    position: relative;
    padding-left: 12px;
}

.AddonsBodyList li:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #008000;
    left: 0;
    top: 48%;
    transform: translate(0, -50%);
}

.AccordingHeadSec  {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AccordingTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    width: 30%;
    color: #333;
    margin: 0;
}

.AccordingPrice {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    width: 30%;
    color: #333;
    margin: 0;
    display: flex;
    align-items: center;
}

.AccordingPrice svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.AccordingBUSec {
    display: flex;
}

.AddBU {
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #008000;
    text-align: center;
    display: flex;
    align-items: center;
    border: 1.5px solid #008000;
    border-radius: 30px;
    background: #fff;
    padding: 6px 10px;
    transition: all .3s ease-in-out;
}

.AddBU:hover {
    color: #fff;
    border: 1.5px solid #008000;
    background: #008000;
}

.AddBU svg,
.AddedBU svg {
    width: 18px;
    height: 18px;
    margin-right: 3px;
}

.AddedBU {
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    border: 1.5px solid #008000;
    border-radius: 30px;
    background: #008000;
    padding: 6px 10px;
}

.AccordingBody {
    display: flex;
    align-items: flex-start;
}

.AccordingBody img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    margin-right: 25px;
}

.AccordingBodyTitle {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    color: #333;
    margin: 0 0 8px 0px;
}

.AccordingBodyText {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #606060;
    margin: 0;
}

.AddonDropdown {
    width: 100%;
    padding: 40px 0 20px 0;
}

.AddonDropdownTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin: 0 0 8px 0;
    text-transform: uppercase;
}

.AddonDropdownFormInput {
    width: 250px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #585858;
}

.AddonDropdownFormInput:focus {
    outline: none;
}

.SideCarDe {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
}

.CarDetailsSec {
    position: relative;
}

.CarDetailsSecTitle {
    margin: 0 0 3px 0;
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

.CarDetailsSecSText {
    margin: 0 0 5px 0;
    font-size: 13px;
    font-weight: 500;
    color: #606060;
}

.CarDetailsSecSeat {
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    color: #5a5a5a;
    margin: 0;
    display: flex;
    align-items: center;
}

.CarDetailsSecSeat img {
    width: 14px;
    height: 14px;
    margin-right: 6px;
}

.SideCarIMG img {
    width: 120px;
}

.SideCarDeBodyRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SmallCardTaxSec {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    padding: 15px 0;
}

.SmallCardTaxTitle {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.SmallCardTaxAmm {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    color: #333;
}

.SmallCardTotal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 15px;
}

.SmallCardTOtalAmm {
    margin: 0;
    font-size: 25px;
    font-weight: 700;
    color: #333;
    position: relative;
}

.SmallCardTOtalAmm::before {
    content: '$';
    position: absolute;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    left: -15px;
    top: 4px;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
    font-size: .875rem;
    justify-content: space-between;
}

.FormGroup {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 48%;
}

.FormGroupFull {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 100%;
}

.FormGroupOneThird {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 31%;
}

.FormLabel {
    color: #333;
    font-size: 13px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
}

.FormControl {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #495057;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    height: 38px;
    line-height: 1.5;
    min-width: 150px;
    padding: 0.375rem 0.4rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}

.FormControl:focus {
    outline: none;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 4px;
}

.BookingFormTitle {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 22px;
}

.ClientDetailsForm {
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 20px;
    margin-bottom: 40px;
}

.ClientDetailsForm:last-of-type{
    border-bottom: none;
    padding-bottom: 10px;
    margin-bottom: 0px;
}

.BookingDone {
    width: 100%;
    display: flex;
    height: 650px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.BookingDone img {
    width: 150px;
    height: 150px;
    margin-bottom: 30px;
}

.BookingTitle {
    font-size: 48px;
    font-weight: 700;
    color: #008000;
    margin-bottom: 8px;
    width: 100%;
    text-align: center;
}

.BookingSmTitle {
    font-size: 18px;
    font-weight: 600;
    color: #767676;
    margin-bottom: 8px;
    width: 100%;
    text-align: center;
}

.BookingSmTitle span {
    color: #343434;
}

.ImportantInfoSec {
    width: 100%;
}

.ImportantInfoTitle {
    font-size: 20px;
    font-weight: 600;
    color: #2a2a2a;
}

.ImpInfoRow {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #f3f3f3;
    align-items: flex-start;
    margin: 30px 0;
    padding-bottom: 30px;
}

.ImpInfoRow:last-of-type {
    border-bottom: none;
    margin: 0px 0;
    padding-bottom: 0px;
}

.ImpInfoSmallSec {
    min-width: 250px;
    width: 250px;
    display: flex;
    align-items: center;
}

.ImpInfoSmallSec img {
    min-width: 30px;
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.ImpInfoTitle {
    font-size: 16px;
    font-weight: 600;
    color: #2a2a2a;
    margin-bottom: 0;
}

.ImpInfoBigSec {
    width: 100%;
}

.ImpInfoBigSecText {
    font-size: 13px;
    font-weight: 500;
    color: #606060;
    margin-bottom: 0;
}

.InfoPopBU,
.InfoPopBU:hover {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -15px;
    right: -15px;
    background: #fb2424;
    color: #fff;
    min-width: 30px;
    border-radius: 50%;
    padding: 0;
}

.InfoPopBU svg {
    width: 18px;
}