body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
body {
  font-family: 'Montserrat', sans-serif !important;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url("./fonts/proxima-nova.otf");
  font-weight: 100;
}

@font-face {
  font-family: "ProximaThin";
  src: local("ProximaThin"),
    url("./fonts/proxima-nova-thin.otf");
  font-weight: 100;
}

@font-face {
  font-family: "ProximaLight";
  src: local("ProximaLight"),
    url("./fonts/proxima-nova-light.otf");
  font-weight: 200;
}

@font-face {
  font-family: "ProximaRegular";
  src: local("ProximaRegular"),
    url("./fonts/proxima-nova-regular.otf");
  font-weight: 300;
}

@font-face {
  font-family: "ProximaMedium";
  src: local("ProximaMedium"),
    url("./fonts/proxima-nova-medium.otf");
  font-weight: 400;
}

@font-face {
  font-family: "ProximaSemibold";
  src: local("ProximaSemibold"),
    url("./fonts/proxima-nova-semibold.otf");
  font-weight: 500;
}

@font-face {
  font-family: "ProximaBold";
  src: local("ProximaBold"),
    url("./fonts/proxima-nova-bold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "ProximaExtrabold";
  src: local("ProximaExtrabold"),
    url("./fonts/proxima-nova-extrabold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "ProximaBlack";
  src: local("ProximaBlack"),
    url("./fonts/proxima-nova-black.otf");
  font-weight: 800;
}

form {
  width: 100%;
}

.CustomerReview .react-multiple-carousel__arrow {
  bottom: 0;
  bottom: 0px;
  background: transparent;
  border-radius: 5px;
  min-width: 30px;
  min-height: 30px;
  transition: all .3s;
}

.CustomerReview .react-multiple-carousel__arrow:hover {
  background: rgb(0 0 102 / 80%) !important;
}

.CustomerReview .react-multiple-carousel__arrow::before {
  font-size: 18px;
  color: #333;
}

.CustomerReview .react-multiple-carousel__arrow:hover::before {
  color: #fff;
}

.CustomerReview .react-multiple-carousel__arrow--left {
  left: calc(47% + 1px);
}

.CustomerReview .react-multiple-carousel__arrow--right {
  right: calc(47% + 1px);
}

.CustomerReview .react-multi-carousel-list {
  position: unset;
}

.TrendingSec {
  padding-top: 250px;
  margin-top: -250px;
}

.ReservationStepper .MuiStep-root {
  padding: 0;
}

.ReservationStepper .MuiStep-root .MuiStepLabel-root {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer {
  padding: 0;
  margin-bottom: 15px;
}

.ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer svg {
  width: 60px;
  height: 60px;
}

.ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer svg.Mui-active,
.ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer svg.Mui-completed {
  color: #008000;
}

.ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4A4A4A;
}

.ReservationStepper .MuiStepConnector-root {
  padding-top: 30px;
}

.ReservationStepper .MuiStepConnector-root .MuiStepConnector-line {
  border-top-width: 4px;
}

.ReservationStepper .MuiStepConnector-root.Mui-active .MuiStepConnector-line,
.ReservationStepper .MuiStepConnector-root.Mui-completed .MuiStepConnector-line {
  border-color: #008000;
  border-top-width: 3px;
}

.ReservationStepper {
  padding: 90px 0 !important;
  align-items: flex-start !important;
}

.StepperBuSec {
  width: 100%;
  padding: 20px 0 !important;
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 2;
  margin: 0 -5px;
  width: Calc(100% + 10px);
}

.ReservationSec .container>div {
  margin-bottom: 30px;
}

.TrendingBUBrown {
  width: auto !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 30px !important;
  background: #7C4836 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 6px !important;
  margin-left: 0px !important;
  position: relative !important;
  overflow: hidden !important;
  transition: all .3s ease-in-out !important;
}

.TrendingBUBrown:hover {
  background: #532d20 !important;
}

.TrendingBUBrown.Mui-disabled,
.TrendingBUBrown.Mui-disabled:hover {
  color: rgba(0, 0, 0, 0.26) !important;
  background: #e5e5e5 !important;
}

.TrendingBUGreen {
  width: auto !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 30px !important;
  background: #008000 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 6px !important;
  margin-left: 0px !important;
  position: relative !important;
  overflow: hidden !important;
  transition: all .3s ease-in-out !important;
}

.TrendingBUGreen:hover {
  background: #015801 !important;
}

.TrendingBUGreen.Mui-disabled,
.TrendingBUGreen.Mui-disabled:hover {
  color: rgba(0, 0, 0, 0.26) !important;
  background: #e5e5e5 !important;
}

.AddOnAccording .MuiPaper-root.Mui-expanded {
  margin: 0 !important;
}

.AddOnAccording .MuiPaper-root {
  box-shadow: none !important;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0;
}

/* .AddOnAccording .MuiPaper-root:last-child {
  border: none;
} */

.AddOnAccording .MuiPaper-root::before {
  display: none;
}

.AddOnAccording .MuiPaper-root.Mui-expanded .MuiButtonBase-root.Mui-expanded {
  min-height: 48px !important;
}

.AddOnAccording .MuiPaper-root .MuiButtonBase-root {
  padding: 15px 0 !important;
}

.AddOnAccording .MuiPaper-root.Mui-expanded .MuiButtonBase-root.Mui-expanded .MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

.AddOnAccording {
  margin: 25px 0;
}

.AddOnAccording .MuiPaper-root .MuiCollapse-root {
  border-top: 1px solid #f3f3f3;
}

.AddOnAccording .MuiPaper-root .MuiCollapse-root .MuiAccordionDetails-root {
  padding: 25px 15px !important;
}

.MuiDialog-container>div {
  overflow: unset;
}

.CustomerDash .MuiBox-root {
  /* border-bottom: 1px solid #dee2e6; */
  width: 100%;
}

.CustomerDash button.MuiButtonBase-root {
  padding: 10px 24px !important;
  font-size: 15px !important;
  color: #666 !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  opacity: 1;
}

.CustomerDash button.MuiButtonBase-root.Mui-selected {
  color: #020281 !important;
}

.CustomerDash .MuiTabs-indicator {
  background-color: #020281;
}

.CustomerDash button.MuiButtonBase-root span {
  display: none;
}

.CustomerDashDetails {
  padding: 40px 0;
}

.CustomerDashDetails .MuiBox-root {
  padding: 0;
}

.ProductSlider .react-multi-carousel-list {
  position: relative;
  padding-bottom: 50px;
}

.ProductSlider .react-multiple-carousel__arrow {
  bottom: 0px;
  background: transparent;
  border-radius: 5px;
  min-width: 30px;
  min-height: 30px;
  transition: all .3s;
}

.ProductSlider .react-multiple-carousel__arrow:hover {
  bottom: 0;
  bottom: 0px;
  background: rgb(0 0 102 / 80%);
  border-radius: 5px;
  min-width: 30px;
  min-height: 30px;
}

.ProductSlider .react-multiple-carousel__arrow::before {
  font-size: 18px;
  color: #333;
}

.ProductSlider .react-multiple-carousel__arrow:hover::before {
  font-size: 18px;
  color: #fff;
}

.ProductSlider .react-multiple-carousel__arrow--left {
  left: calc(47% + 1px);
}

.ProductSlider .react-multiple-carousel__arrow--right {
  right: calc(47% + 1px);
}

.FAQArea .MuiPaper-root {
  box-shadow: none;
  border-bottom: 1px solid #cdcdcd;
}

.FAQArea .MuiPaper-root:last-of-type {
  border: none;
}

.FAQArea .MuiPaper-root::before {
  display: none;
}

.FAQArea .MuiButtonBase-root {
  padding: 0;
  min-height: 64px;
}

.FAQArea .MuiAccordionDetails-root {
  padding: 16px 16px;
  background: #f3f7f3;
}

.FAQArea .MuiPaper-root.Mui-expanded {
  margin: 0px 0;
}

.CustomerDash .MuiTabs-scroller {
  overflow: auto !important;
}

.UserName {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/************************** Admin CSS ************************************/
.DashboardMainBody {
  width: 100%;
  position: relative;
  padding-left: 85px;
}

.TableContent header {
  padding: 0;
}

.TableFilterHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DataTableSearch {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #bcbcbc;
}

.DataTableSearch .MuiFormControl-root {
  width: 100%;
}

.DataTableSearch .MuiFormControl-root input {
  width: 100%;
  height: auto;
  padding: 5px 15px 5px 0;
  font-size: 14px;
}

.DataTableSearch .MuiOutlinedInput-notchedOutline {
  display: none;
}

.DataTableSearch .CloseBU,
.DataTableSearch .CloseBU:hover {
  padding: 0;
  min-width: 40px;
  background: none;
  display: flex;
  justify-content: flex-end;
  color: #333;
}

.DataTableSearch .CloseBU svg {
  width: 18px;
  height: 18px;
}

.TableFilterBUSec {
  width: auto;
  display: flex;
  margin-left: 30px;
}

.TableAddBU {
  align-items: center;
  padding: 9px 14px;
  background: #40bf0c;
  display: flex;
  min-height: 0;
  color: #fff !important;
  border-radius: 5px;
  transition: all .3s;
  outline: none;
  height: 38px;
  cursor: pointer;
}

.TableAddBU:hover {
  background: #329909;
}

.TableAddBU svg {
  width: 20px;
  height: 20px;
}

.DataTable .rdt_Table .rdt_TableHead .rdt_TableCol div,
.DataTable .rdt_Table .rdt_TableHead .rdt_TableCol div:hover,
.DataTable .rdt_Table .rdt_TableHead .rdt_TableCol div:focus {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  opacity: 1;
}

.DataTable .rdt_Table .rdt_TableHead .rdt_TableCol div span {
  margin-left: 15px;
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow>div {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 58px;
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background-color: rgba(0, 0, 255, 4%);
  transition: all .3s ease-in-out;
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow:nth-child(even) {
  background-color: rgba(255, 255, 255, 100%);
  transition: all .3s ease-in-out;
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow div {
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  opacity: 1;
  /* transition: all .3s ease-in-out; */
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow:hover div {
  opacity: 1;
}

.DataTable .rdt_Table .rdt_TableBody .rdt_TableRow:hover {
  background-color: #eee;
}

.dropdown button {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.dropdown button:after {
  display: none;
}

.dropdown button svg {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.breadcrumb li a {
  text-decoration: none;
  color: #4a4a4a;
  opacity: .7;
  font-size: 14px;
  font-weight: 500;
  transition: all .3s ease-in-out;
}

.breadcrumb li a:hover {
  text-decoration: underline;
  opacity: 1;
}

.breadcrumb li p {
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
}

.LoaderCard {
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding: 1.5rem;
  margin: 1.5rem;
}

.Loader svg {
  width: 100%;
}

.TableLoader svg {
  height: 970px;
}

.FromLoader svg {
  height: 420px;
}

.CusModal .modal-header {
  padding: 0 20px;
  background-color: #000066;
  border-bottom: none;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  height: 60px;
}

.CusModal .modal-title {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  color: #fff;
}

.CusModal .modal-header .btn-close {
  border: none;
  color: #fff;
  padding: 0;
  filter: brightness(0) invert(1);
  opacity: 1;
}

.CusModal .modal-header .btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}

.CusModal .modal-body {
  padding: 8px 24px;
}

.CusModal .modal-body p.Message {
  font-size: 15px;
  color: #858585;
  margin: 20px 0;
}

.PopUpCanBU {
  background: transparent !important;
  border: 1px solid #230058 !important;
  color: #230058 !important;
  transition: all .3s ease-in-out !important;
  min-width: 90px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  margin-right: 10px !important;
}

.PopUpCanBU:hover {
  background: #230058 !important;
  border: 1px solid #230058 !important;
  color: #fff !important;
}

.PopUpSubmitBU {
  background: #f44336 !important;
  border: 1px solid #f44336 !important;
  color: #fff !important;
  transition: all .3s ease-in-out !important;
  min-width: 90px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
}

.PopUpSubmitBU:hover {
  background: #c13429 !important;
  border: 1px solid #c13429 !important;
  color: #fff !important;
}

.PopUpSubmitBU2 {
  background: #000066 !important;
  border: 1px solid #000066 !important;
  color: #fff !important;
  transition: all .3s ease-in-out !important;
  min-width: 90px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
}

.PopUpSubmitBU2:hover {
  background: #000066 !important;
  border: 1px solid #000066 !important;
  color: #fff !important;
}

.modal-footer {
  border: none !important;
}

.MainLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background: rgb(255 255 255 / 45%);
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999999999;
}

.MainLoader img {
  width: 80px;
}

.LoadingText {
  text-align: center;
  padding: 15px 0 0 0;
  font-size: 14px;
}

.e-upload-progress-wrap.hide {
  display: none !important;
}

/************************** Admin CSS ************************************/

@media (min-width: 360px) {

  .CustomerReview .react-multiple-carousel__arrow--left,
  .ProductSlider .react-multiple-carousel__arrow--left {
    left: calc(40% + 1px);
  }

  .CustomerReview .react-multiple-carousel__arrow--right,
  .ProductSlider .react-multiple-carousel__arrow--right {
    right: calc(40% + 1px);
  }

  .ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer svg {
    width: 40px;
    height: 40px;
  }

  .ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
    font-size: 4px;
    opacity: 0;
  }

  .ReservationStepper .MuiStepConnector-root {
    padding-top: 20px;
  }

  .ReservationStepper {
    padding: 40px 0 20px 0 !important;
  }
}

@media (min-width: 480px) {}

@media (min-width: 576px) {

  .CustomerReview .react-multiple-carousel__arrow--left,
  .ProductSlider .react-multiple-carousel__arrow--left {
    left: calc(43% + 1px);
  }

  .CustomerReview .react-multiple-carousel__arrow--right,
  .ProductSlider .react-multiple-carousel__arrow--right {
    right: calc(43% + 1px);
  }

  .ReservationStepper {
    padding: 60px 0 20px 0 !important;
  }
}

@media (min-width: 768px) {
  .CustomerReview .react-multiple-carousel__arrow--left {
    left: calc(45% + 1px);
  }

  .CustomerReview .react-multiple-carousel__arrow--right,
  .ProductSlider .react-multiple-carousel__arrow--right {
    right: calc(45% + 1px);
  }

  .ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
    font-size: 12px;
    opacity: 1;
  }

  .ReservationStepper {
    padding: 60px 0 60px 0 !important;
  }
}

@media (min-width: 992px) {

  .CustomerReview .react-multiple-carousel__arrow--left,
  .ProductSlider .react-multiple-carousel__arrow--left {
    left: calc(47% + 1px);
  }

  .CustomerReview .react-multiple-carousel__arrow--right,
  .ProductSlider .react-multiple-carousel__arrow--right {
    right: calc(47% + 1px);
  }

  .ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer svg {
    width: 60px;
    height: 60px;
  }

  .ReservationStepper .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
    font-size: 16px;
  }

  .ReservationStepper .MuiStepConnector-root {
    padding-top: 30px;
  }

  .ReservationStepper {
    padding: 90px 0 90px 0 !important;
  }
}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}

@media (min-width: 1600px) {}

@media (min-width: 1800px) {}


.DatePickerNew .react-datepicker-wrapper .react-datepicker__input-container {
  display: none !important;
}

.DatePickerNew .react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  border-radius: 0.3rem;
  background-color: #F1F1F1;
  color: #ABABAB;
}

.DatePickerNew .react-datepicker__day--disabled:hover {
  background-color: #F1F1F1;
  color: #ABABAB;
}

.DatePickerNew .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #008000;
  color: #fff;
}

.CustomSelect .MuiFormLabel-root {
  font-size: 14px;
  -webkit-transform: translate(14px, 10px) scale(1);
  -moz-transform: translate(14px, 10px) scale(1);
  -ms-transform: translate(14px, 10px) scale(1);
  transform: translate(14px, 10px) scale(1);
}

.CustomSelect .MuiFormLabel-root.Mui-focused,
.CustomSelect .MuiFormLabel-root.MuiFormLabel-filled {
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
}

.CustomSelect .MuiInputBase-root {
  font-size: 14px;
}

.CustomSelect .MuiInputBase-root .MuiSelect-select {
  padding: 9px 14px;
}

/*******************Full Calendar***********************/
.fc-toolbar-title {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 500;
}

.fc-button {
  background-color: #006 !important;
  color: #fff !important;
  padding: 0 5px !important
}

.fc-col-header-cell a {
  color: #000 !important;
  text-decoration: none !important;
  font-weight: 600;
}

.fc-daygrid-day-number {
  background-color: #006;
  color: #fff;
  padding: 3px !important;
  border-radius: 15px;
  text-decoration: none;
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 5px 0;
}

.fc-daygrid-event {
  /*background-color: rgba(0, 0, 102, 0.9) !important;*/
  color: #fff !important;
  cursor: pointer;
  font-size: 11px;
  padding: 4px;
  margin: 3px auto !important;
  width: 90%;
}

/******************* Full calendar **********************/
.FullCalendar {
  width: 100% !important;
  position: relative !important;
}

/*.FullCalendar .fc-header-toolbar {
  justify-content: flex-start !important;
}*/

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk {
  display: flex !important;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk .fc-myCustomButton-button {
  width: auto;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 25px;
  background: #000066 !important;
  color: #fff;
  border: none;
  border-radius: 6px;
  margin-left: 15px;
  position: relative;
  overflow: hidden;
  transition: all .3s ease-in-out;
  padding: 12px !important;
}


.FullCalendar .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 40px !important;
  color: #505050 !important;
  margin: 0 !important;
  font-family: "Inter", sans-serif !important;
  min-width: 100px !important;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk button.fc-today-button {
  display: none !important;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #6d4d9f !important;
  font-size: 15px !important;
  padding: 6px !important;
}

.FullCalendar .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid {
  border: 1px solid #e3e6e9 !important;
  border-radius: 16px !important;
  overflow: hidden !important;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid thead td,
.FullCalendar .fc-view-harness table.fc-scrollgrid tbody>tr>td {
  border: none !important;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td,
.FullCalendar .fc-view-harness table.fc-scrollgrid table.fc-col-header th {
  border: 1px solid #e3e6e9 !important;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid table.fc-col-header th {
  padding: 10px !important;
  background-color: #f4fdf4 !important;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid table.fc-col-header th a {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  color: #505050 !important;
  font-family: "Inter", sans-serif !important;
  text-align: center !important;
}

.FullCalendar .fc .fc-daygrid-day-top {
  justify-content: center !important;
  padding: 16px 12px 12px 12px !important;
}

.FullCalendar .fc .fc-daygrid-day-number {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  color: #505050 !important;
  font-family: "Inter", sans-serif !important;
  background: transparent;
  /* padding: 16px 12px 12px 12px; */
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-past.fc-day-other .fc-daygrid-day-frame:before,
.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-future.fc-day-other .fc-daygrid-day-frame:before {
  background-image: url("./global-img/gradientIMG.svg") !important;
  position: absolute !important;
  width: calc(100% - 2px) !important;
  height: calc(100% - 2px) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-today {
  background-color: #fff !important;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-today .fc-daygrid-day-top {
  padding: 14px 12px 12px 12px !important;
}

.FullCalendar .fc-view-harness table.fc-scrollgrid tbody table.fc-scrollgrid-sync-table td.fc-day-today .fc-daygrid-day-number {
  width: 28px !important;
  height: 28px !important;
  background-color: #050569 !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  color: #fff !important;
  font-family: "Inter", sans-serif !important;
}

.FullCalendar .fc-event {
  padding: 4px 8px !important;
  margin: 6px 6px 0 6px !important;
  transition: all 0.2s ease-in-out !important;
}

.FullCalendar .fc-event.fc-tour-custom {
  background-color: #6d4d9f !important;
}

.FullCalendar .fc-event.fc-tour-custom:hover {
  background-color: #230058 !important;
}

.FullCalendar .fc-event.fc-openhouse-custom {
  background-color: #efd763 !important;
}

.FullCalendar .fc-event.fc-openhouse-custom:hover {
  background-color: #d2bd54 !important;
}

.FullCalendar .fc-event .fc-daygrid-event-dot {
  display: none !important;
}

.FullCalendar .fc-event .fc-event-time,
.FullCalendar .fc-event .fc-event-title {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  color: #fff !important;
  font-family: "Inter", sans-serif !important;
  flex-grow: unset !important;
}

.FullCalendar .fc-event .fc-event-title {
  width: auto !important;
  max-width: 110px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-wrap: nowrap !important;
  margin-right: 4px !important;
}

.FullCalendar .fc-event.fc-openhouse-custom .fc-event-time,
.FullCalendar .fc-event.fc-openhouse-custom .fc-event-title {
  color: #343434 !important;
  font-weight: 600 !important;
}

.FullCalendar .fc-more-link {
  color: #ff8271 !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  line-height: 12px !important;
  border: 1px solid !important;
  background: #ffe8e5 !important;
  border-radius: 10px !important;
  padding: 3px 4px !important;
  margin: 5px 0 0 5px !important;
}

.FullCalendar .fc .fc-popover {
  box-shadow: #3e557826 0px 4px 20px 0 !important;
  position: absolute !important;
  z-index: 99 !important;
  width: 14.3% !important;
  border-radius: 8px !important;
  border: none !important;
}

.FullCalendar .fc .fc-more-popover .fc-popover-body {
  min-width: 130px !important;
  padding: 0px 0px 5px 0px !important;
}

.FullCalendar .fc .fc-popover-header {
  background: transparent !important;
}

.FullCalendar .fc .fc-popover-title {
  margin: 4px 2px 0px 3px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #505050 !important;
}

/******************* Full calendar **********************/

.NewPopUp .MuiTypography-root {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: flex-start;
  justify-content: space-between;
  height: auto;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  color: #343434;
}

.NewPopUp .MuiTypography-root button {
  background: none;
  border: none;
  color: #343434;
  padding: 0;
}

.NewPopUp .MuiTypography-root button svg {
  width: 32px;
  height: 32px;
  stroke-width: 1;
}

.NewPopUp .MuiDialogTitle-root {
  padding: 0;
  background: #fff;
}

.NewPopUp .MuiTypography-root {
  background: #fff;
  height: auto;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.NewPopUp .CheckBox .MuiTypography-root {
  background: #fff;
  height: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.NewPopUp .MuiTypography-root button {
  color: #343434;
}

.NewPopUp .MuiTypography-root .MuiPickersYear-root button:hover,
.NewPopUp .MuiTypography-root .MuiPickersYear-root button:focus {
  background-color: rgb(109 77 159 / 10%) !important;
}

.NewPopUp .MuiTypography-root .MuiPickersYear-root button.Mui-selected {
  background-color: #6d4d9f !important;
  color: #fff;
}

.NewPopUp .MuiTypography-root button svg {
  width: 30px;
  height: 30px;
  stroke-width: 1.5;
}

.NewPopUp .MuiDialogContent-root,
.NewPopUp .MuiDialogContent-root .MuiDialogContentText-root {
  padding: 0;
  margin: 0;
}

.NewPopUp .MuiDialogContent-root::-webkit-scrollbar {
  display: none;
}

.NewPopUp .MuiDialogContent-root {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.NewPopUp .MuiDialogActions-root {
  padding: 12px 0 0 0;
  justify-content: flex-start;
}

.NewPopUp .MuiPaper-root {
  padding: 24px 32px;
  overflow: unset !important;
  border-radius: 10px !important;
}

.NewPopUp.CustomWidthPops .MuiPaper-root {
  max-width: 1100px;
}

.NewPopUp.CustomWidthLeasePops .MuiPaper-root {
  max-width: 1100px;
}

.NewPopUp.AddTenantDash .MuiPaper-root {
  padding: 24px 32px;
  overflow: auto !important;
  border-radius: 10px !important;
}

.CustomWidthPopUs.NewPopUp .MuiDialog-paperWidthSm {
  max-width: 660px;
}

.NewPopUpForLease.NewPopUp .MuiPaper-root {
  padding: 20px 20px;
  overflow: unset !important;
}

.NewPopUp .ModalFormGroup {
  width: 100%;
  padding: 25px 0;
}

.NewPopUp.CustomWidthPopUs .ModalFormGroup {
  width: 100%;
  padding: 15px 0;
}

.NewPopUp .SubmitPopupBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin-left: 12px !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .SubmitPopupBU:hover {
  background: #e16e5f !important;
  border: 1px solid #e16e5f !important;
}

.NewPopUp .ArchivePopupBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #6d4d9f !important;
  background: #fff !important;
  border: 1px solid #6d4d9f !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin-left: 12px !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .ArchivePopupBU:hover {
  background: #6d4d9f !important;
  color: #fff !important;
}

.NewPopUp .AddPopupBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin-right: 16px !important;
  margin-left: 0 !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .AddPopupBU:hover {
  background: #e16e5f !important;
  border: 1px solid #e16e5f !important;
}

.NewPopUp .CancelPopupBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .CancelPopupBU:hover {
  background: #f1ecf3 !important;
  color: #343434 !important;
  border: 1px solid #e1e1e1 !important;
}

.NewPopUp .FileCanBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0px !important;
  padding: 12px 30px !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .FileCanBU:hover {
  background: #ebeef7 !important;
  color: #343434 !important;
  border: 1px solid #ebeef7 !important;
}

.NewPopUp .FileReBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  background: #2A5BEE !important;
  border: 1px solid #2A5BEE !important;
  border-radius: 0px !important;
  padding: 12px 30px !important;
  margin-right: 16px !important;
  margin-left: 15px !important;
  text-transform: capitalize !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
}

.NewPopUp .FileReBU:hover {
  background: #1a43c0 !important;
  border: 1px solid #1a43c0 !important;
}

.NewPopUp .SubmitPopupBUPopPin {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  background: #6d4d9f;
  border: 1px solid #6d4d9f;
  border-radius: 8px;
  padding: 10px 16px;
  margin-right: 0px;
  margin-left: 0;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .NewPopUp.CustomWidthPops .MuiPaper-root {
    max-width: 750px;
    padding: 24px 20px;
  }

  .NewPopUp.CustomWidthPops .MuiDialogContent-root {
    padding-left: 12px;
    padding-right: 12px;
  }

  .NewPopUp.CustomWidthPops .MuiTypography-root {
    padding-left: 12px;
    padding-right: 12px;
  }

  .NewPopUp.CustomWidthLeasePops .MuiPaper-root {
    max-width: 750px;
  }
}

@media screen and (min-width: 1300px) {
  .NewPopUp.CustomWidthPops .MuiPaper-root {
    max-width: 1100px;
  }

  .NewPopUp.CustomWidthLeasePops .MuiPaper-root {
    max-width: 1100px;
  }
}

@media screen and (max-width: 959px) {
  .NewPopUp.CustomWidthPops .MuiPaper-root {
    padding: 24px 10px;
  }

  .NewPopUp.CustomWidthPops .MuiDialogContent-root {
    padding-left: 12px;
    padding-right: 12px;
  }

  .NewPopUp.CustomWidthPops .MuiTypography-root {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media screen and (max-width: 767px) {

  .CustomWidthPopUs.MobileForm,
  .MobileForm {
    z-index: 999;
  }

  .CustomWidthPopUs.MobileForm .MuiPaper-root,
  .MobileForm .MuiPaper-root {
    max-width: 100%;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
    max-height: 100%;
  }

  .NewPopUp.MobileForm .MuiTypography-root button {
    display: none;
  }

  .NewPopUp.MobileForm .MuiTypography-root {
    align-items: center;
    justify-content: flex-start;
  }

  .NewPopUp.MobileForm .MuiDialogContent-root::-webkit-scrollbar {
    display: none;
  }

  .NewPopUp.MobileForm .MuiDialogContent-root {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@media screen and (max-width: 575px) {

  .CustomWidthPopUs.MobileForm .MuiPaper-root,
  .MobileForm .MuiPaper-root {
    padding: 20px 20px;
  }

  .NewPopUp .MuiPaper-root {
    padding: 15px 15px;
  }
}

@media screen and (max-width: 479px) {
  .NewPopUp.MobileForm .CancelPopupBU {
    width: 48%;
    padding: 12px 16px !important;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .NewPopUp.MobileForm .SubmitPopupBU {
    width: 48%;
    margin: 0;
    padding: 12px 16px !important;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}