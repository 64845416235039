.BannerConArea {
    width: 100%;
    position: relative;
    height: 100%;
}

.BannerConArea::before {
    content: '';
    background-color: rgb(0 0 0 / 42%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.InnerBannerRow {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InnerBannerTitle {
    font-weight: 800;
    font-size: 78px;
    line-height: 110px;
    color: #fff;
    position: relative;
    text-shadow: 1px 1px 20px black;
    text-align: center;
}

.ProductSec {
    width: 100%;
    position: relative;
    padding: 50px 0 80px 0;
}

.BodyMRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
}

.ProductTitle {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 30px;
}

.ProductSlider {
    width: 100%;
}

.Item {
    width: 90%;
    margin: 15px auto;
    position: relative;
}

.ItemUnder {
    align-items: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.CardTexture {
    position: absolute;
    width: 600px;
    top: -200px;
    right: -310px;
    opacity: .7;
}

.ItemUnder img.CardIMG {
    width: 100%;
    height: 150px;
    object-fit: contain;
    object-position: center;
    margin-bottom: 35px;
    position: relative;
}

.CarItemTitle {
    color: #2a2a2a;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
    text-transform: capitalize;
    text-transform: capitalize;
    text-align: center;
    width: 100%;
    position: relative;
}

.CarItemText {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #878787;
    margin-bottom: 2px;
    width: 100%;
    text-align: center;
    position: relative;
}

.CarItemText2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #878787;
    margin-bottom: 25px;
    width: 100%;
    text-align: center;
    position: relative;
}

.CarItemPrice {
    color: #2a2a2a;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    margin: 0 0 20px 0;
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.CarItemPrice span {
    color: #5a5a5a;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    margin-left: 10px;
    position: relative;
}

.TrendingBUGreen {
    width: 100%;
    max-width: 200px;
    font-size: 14px;
    font-weight: 600;
    padding: 14px 22px;
    background: #008000;
    color: #fff;
    border: none;
    border-radius: 6px;
    margin: auto;
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.TrendingBUGreen span {
    position: relative;
    z-index: 1;
}

.TrendingBUGreen:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background: #015801;
    z-index: 0;
}
  
.TrendingBUGreen:hover:after {
    width: 100%;
    left: 0;
}

.ServiceSec {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 120px 0 80px 0;
}

.CarBG {
    max-width: 850px;
    max-height: 700px;
    object-fit: contain;
    object-position: center;
    position: absolute;
    z-index: 0;
    right: 0;
    bottom: 0;
}

.ProductHead {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 30px;
}

.ServiceTitle {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0 0 20px 0;
}

.ProductSmallTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    width: 600px;
    text-align: center;
    margin: 0 auto;
}

.ServicesRow {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

.ServicesCard {
    width: 31%;
    margin: 15px 0;
    background: #fff;
    padding: 0;
    box-shadow: 1px 1px 20px 1px #dcdcdc;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    transition: all .3s ease-in-out;
}

.ServicesCardUnder {
    width: 100%;
    margin: 15px 0;
    background: #fff;
    padding: 0;
    box-shadow: 1px 1px 20px 1px #dcdcdc;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    transition: all .3s ease-in-out;
}

.BlogBody {
    width: 100%;
    padding: 30px 25px;
    position: relative;
}

.ServicesCard:hover,
.ServicesCardUnder:hover {
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
}

.ServicesCard img,
.ServicesCardUnder img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin-bottom: 0px;
}

.ServiceCardTitle {
    font-size: 22px;
    color: #2a2a2a;
    line-height: 30px;
    text-align: left;
    font-weight: 700;
    width: 100%;
    margin: 0 0 15px 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ServiceText {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #878787;
    margin-bottom: 0px;
    width: 100%;
    text-align: left;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.InsuranceSec {
    width: 100%;
    height: auto;
    background: #f3f3f3;
    position: relative;
    padding: 250px 0 100px 0;
}

.waveInsur {
    width: 100%;
    height: auto;
    position: absolute;
    top: -1px;
    left: 0;
}

.InsurTitle {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0 0 40px 0;
}

.InsurSmallTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4a4a4a;
    width: 100%;
    text-align: center;
    width: 1000px;
    text-align: center;
    margin: 0 auto;
}

.InsurAmount {
    width: fit-content;
    border: 3px solid #212529;
    font-size: 38px;
    margin: 0px auto;
    font-weight: 400;
    padding: 10px 30px;
    background: #212529;
    color: #fff;
    border-radius: 60px;
}

.BlogDeBU,
.BlogDeBU:hover {
    font-size: 16px;
    margin-top: 15px;
    display: inline-flex;
    font-weight: 500;
    text-decoration: none;
    align-items: center;
    color: #006;
    transition: all .3s ease-in-out;
    position: relative;
}

.BlogDeBU::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 1.5px;
    background: #006;
    bottom: 0;
    left: 0;
    transition: all .3s ease-in-out;
}

.BlogDeBU:hover::after {
    width: 92px;
}

.BlogDeBU svg {
    width: 18px;
    margin-left: 8px;
    transition: all .3s ease-in-out;
}

.BlogDeBU:hover svg {
    opacity: 0;
    margin-left: 15px;
}

@media (min-width: 360px) {
    .ProductTitle,
    .ServiceTitle,
    .InsurTitle {
        font-size: 28px;
        line-height: 48px;
    }
    .InnerBannerTitle {
        font-size: 36px;
        line-height: 60px;
    }
    .ProductSmallTitle {
        width: 100%;
    }
    .ServicesCard {
        width: 100%;
    }
}

@media (min-width: 576px) {
    .ProductTitle,
    .ServiceTitle,
    .InsurTitle {
        font-size: 40px;
        line-height: 58px;
    }
    .InnerBannerTitle {
        font-size: 48px;
        line-height: 80px;
    }
}

@media (min-width: 768px) {
    .InnerBannerTitle {
        font-size: 58px;
        line-height: 100px;
    }
    .ProductSmallTitle {
        width: 600px;
    }
    .ServicesCard {
        width: 48%;
    }
}

@media (min-width: 992px) {
    .ProductTitle,
    .ServiceTitle,
    .InsurTitle {
        font-size: 50px;
        line-height: 68px;
    }
    .InnerBannerTitle {
        font-size: 68px;
        line-height: 110px;
    }
    .ServicesCard {
        width: 31%;
    }
}

@media (min-width: 1200px) {
    .InnerBannerTitle {
        font-size: 78px;
    }
}

@media (min-width: 1400px) {}

@media (min-width: 1800px) {}