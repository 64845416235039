.BookingDone {
  width: 100%;
  display: flex;
  padding: 80px 0 50px 0;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.BookingDone img {
  width: 150px;
  height: 150px;
  margin-bottom: 30px;
}
.WeeklySlotSec {
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid #e2e2e2;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
.SelectSec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.SelectBox {
  font-size: 14px;
  border: 1px solid #999;
  color: #4abd5d;
  padding: 7px 12px;
  border-radius: 5px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: 8px;
  cursor: pointer;
}
.SelectBoxSelected {
  font-size: 14px;
  border: 1px solid #4abd5d;
  color: #fff;
  background-color: #4abd5d;
  padding: 7px 12px;
  border-radius: 5px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: 8px;
  cursor: pointer;
}

.SelectBox span,
.SelectBoxSelected span {
  position: absolute;
  padding: 10px 44px;
  opacity: 0;
}
.FormButtonArea {
  width: 100%;
}
.PopUpCanBU,
.PopUpCanBU:hover {
  width: 30px !important;
  height: 30px !important;
  background: #000066 !important;
  color: #fff !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  margin: 0 !important;
  min-width: 20px !important;
  position: absolute !important;
  top: -16px !important;
  right: -16px !important;
}

.PopUpCanBU svg {
  width: 22px;
  height: 22px;
}

.card {
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  /* position: relative; */
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding-bottom: 0;
}

.card .cardbody {
  padding: 1.5rem;
}

.FullCalendar {
  width: 100%;
  padding: 30px 30px;
}

.FerryIDSec {
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.FerryIDSec p.Title {
  font-size: 24px;
  line-height: 48px;
  font-weight: 600;
  color: #070908;
  letter-spacing: .5px;
  margin: 0 0 20px 0;
  width: 100%;
  text-align: center;
}

.FerryIDSec>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FerryIDSec>div input.TextField {
  width: calc(100% - 190px);
  font-size: 18px;
  line-height: 36px;
  font-weight: 400;
  color: #070908;
  background: #fff;
  border: 1px solid #d1d2d2;
  border-radius: 40px;
  padding: 13px 30px;
  letter-spacing: 1px;
}

.FerryIDSec>div input.TextField:focus {
  outline: none;
}

.FerryIDSec>div input.SubmitBu {
  width: 180px;
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
  color: #ffffff;
  background: #000066;
  text-transform: uppercase;
  border-radius: 40px;
  padding: 14px 15px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
  letter-spacing: 1px;
}

.ButtonAction {
  padding: 12px 0 0 0;
  justify-content: flex-start;
  display: flex;
  align-items: center;
}

.MainCard {
  width: 100%;
}

.AddUserMain {
  width: 100%;
  padding-top: 20px;
}

.AddUserMain2 {
  width: 100%;
  padding-top: 30px;
}

.FormRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.875rem;
}

.FormGroup {
  width: 100%;
  padding-bottom: 15px;
  font-size: 0.875rem;
  position: relative;
}

.Select > div > div {
  min-height: 46px;
  border-color: #e1e1e1;
  border-radius: 8px;
}

.CustomSelect > div {
  border-radius: 8px;
  border-color: #e1e1e1;
  min-height: 44px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding-left: 5px;
}

.FormLabel {
  font-size: 14px;
}

.FerryAmount {
  font-size: 16px;
  margin: 0;
}

.PayPalAmountTitle {
  font-size: 16px;
}
