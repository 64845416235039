.BannerConArea {
    width: 100%;
    position: relative;
    height: 100%;
}

.BannerConArea::before {
    content: '';
    background-color: rgb(0 0 0 / 42%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.InnerBannerRow {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InnerBannerTitle {
    font-weight: 800;
    font-size: 78px;
    line-height: 110px;
    color: #fff;
    position: relative;
    text-shadow: 1px 1px 20px black;
    text-align: center;
}

.ServiceSec {
    width: 100%;
    position: relative;
    padding: 120px 0 80px 0;
}

.CarBG {
    max-width: 700px;
    max-height: 700px;
    object-fit: contain;
    object-position: center;
    position: absolute;
    z-index: 0;
    left: -100px;
    bottom: 100px;
    transform: rotate(180deg) rotateY(45deg);
}

.ContactRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.ContactImg {
    width: 46%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* box-shadow: 0 0 30px 0px #0000001a;
    border-radius: 5px;
    padding: 6px; */
    margin: 0;
    /* background-color: #fff; */
}

.ContactImg img,
.ContactImg iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.CusCon {
    width: Calc(100% - 30px);
    max-width: 1000px;
    margin: 0 auto;
}

.ContactForm {
    width: 46%;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
}

.FormGroup {
    font-size: .875rem;
    padding-bottom: 10px;
    position: relative;
    width: 100%;
}

.FormLabel {
    color: #333;
    font-size: 13px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
}

.FormControl {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #495057;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    height: 38px;
    line-height: 1.5;
    min-width: 150px;
    padding: 0.375rem 0.4rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}

.FormControl:focus {
    outline: none;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 4px;
}

.FormControlTextarea {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #495057;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    height: 180px;
    line-height: 1.5;
    min-width: 150px;
    padding: 12px 16px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
    resize: none;
}

.ContactFormBuSec {
    width: 100%;
    margin-top: 15px;
}

.HeaderBU {
    width: auto;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 30px;
    background: #008000;
    color: #fff;
    border: none;
    border-radius: 6px;
    margin-left: 0px;
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.HeaderBU span {
    position: relative;
    z-index: 1;
}

.HeaderBU:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background: #000066;
    z-index: 0;
}

.HeaderBU:hover:after {
    width: 100%;
    left: 0;
}
.SubmittedMessage {
    font-size: 20px;
    font-weight: 700;
    color: #ff8271;
    margin-bottom: 25px;
  }

  .ConCardSec {
    width: 100%;
    padding: 160px 0 40px 0;
  }

  .CardRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .contactTab {
    width: 50%;
    margin-bottom: 50px;
    padding: 15px;
}

.contactTabInside {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0px 5px 20px 0 #33333324;
    padding: 80px 50px 20px 50px;
    text-align: center;
    position: relative;
}

.ContactIcon {
    padding: 30px;
    width: 90px;
    height: 90px;
    background: #7c4836;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -50%);
}

.ContactIcon svg {
    width: 30px;
    height: 30px;
}

.contactTabInside h5 {
    font-weight: 700;
    text-transform: uppercase;
}

.contactTabInside .InsideDes {
    font-size: 16px;
    line-height: 28px;
    color: #98999a;
    padding: 0;
    width: 100%;
    display: block;
    text-decoration: none;
    margin-top: 5px;
    margin-bottom: 0;
    transition: all .3s ease-in-out;
}

.contactTabInside .InsideDes:hover {
    color: #44ac09;
}

@media (min-width: 360px) {
    .ProductTitle,
    .ServiceTitle,
    .InsurTitle {
        font-size: 28px;
        line-height: 48px;
    }
    .InnerBannerTitle {
        font-size: 36px;
    }
    .ServiceSec {
        padding: 0px 0 60px 0;
    }
    .ContactImg {
        width: 100%;
        height: 300px;
        position: relative;
        margin-bottom: 25px;
    }
    .ContactForm {
        width: 100%;
        margin: auto;
    }
    .contactTab {
        width: 100%;
    }
    .ConCardSec {
        padding: 60px 0 40px 0;
    }
}

@media (min-width: 576px) {
    .ProductTitle,
    .ServiceTitle,
    .InsurTitle {
        font-size: 40px;
        line-height: 58px;
    }
    .InnerBannerTitle {
        font-size: 48px;
    }
    .ContactForm {
        width: 400px;
    }
}

@media (min-width: 768px) {
    .InnerBannerTitle {
        font-size: 58px;
    }
    .contactTab {
        width: 50%;
    }
    .ConCardSec {
        padding: 100px 0 40px 0;
    }
}

@media (min-width: 992px) {
    .ProductTitle,
    .ServiceTitle,
    .InsurTitle {
        font-size: 50px;
        line-height: 68px;
    }
    .InnerBannerTitle {
        font-size: 68px;
    }
    .ServiceSec {
        padding: 120px 0 80px 0;
    }
    .ContactImg {
        width: 46%;
        height: 100%;
        position: absolute;
        margin-bottom: 0;
    }
    .ContactForm {
        width: 46%;
        margin: unset;
    }
    .ConCardSec {
        padding: 160px 0 40px 0;
    }
    .CusCon {
        width: Calc(100% - 30px);
        max-width: 850px;
    }
}

@media (min-width: 1200px) {
    .InnerBannerTitle {
        font-size: 78px;
    }
    .CusCon {
        width: Calc(100% - 30px);
        max-width: 1000px;
    }
}

@media (min-width: 1400px) {}

@media (min-width: 1800px) {}